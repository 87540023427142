const CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY = 'previewDeploySha';
const CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM = 'preview_sha';

/**
 * Clear the preview deployment from local storage and redirect to the current URL
 */
export function clearPreviewDeployment() {
  localStorage.removeItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY);
  const url = new URL(window.location.href);
  // Remove the sha from the URL before loading the page
  url.searchParams.delete(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  window.location.href = url.toString(); // reload the page
}

// The duration in ms after which the preview deployment is considered expired
const LOCAL_STORAGE_EXPIRE_TIME_MS = 1000 * 60 * 10; // 10 minutes

/**
 * Get the preview deployment sha from the URL or local storage
 */
export function getPreviewDeploymentSha() {
  const urlSha = new URLSearchParams(window.location.search).get(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  if (urlSha) {
    // write to local storage
    localStorage.setItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY, `${urlSha}:${Date.now()}`);
  } else {
    // If the sha is not in the URL, check if it's in local storage
    // If it is, reload the page with the sha in the URL
    // This is needed so nginx can route the request to the correct deployment
    const hasSha = localStorage.getItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY);
    if (hasSha) {
      const [sha, timestamp] = hasSha.split(':');
      // If the sha was written to localStorage more than 10 minutes ago, this probably
      // means the preview deployment is old and has expired. In this case, we should clear
      // the preview deployment and reload the page with the latest deployed version.
      if (!timestamp || Date.now() - parseInt(timestamp, 10) > LOCAL_STORAGE_EXPIRE_TIME_MS) {
        clearPreviewDeployment();
        return;
      }
      const url = new URL(window.location.href);
      url.searchParams.set(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM, sha);
      window.location.href = url.toString();
    }
  }
  return urlSha;
}

/**
 * Boolean that returns true if the preview deployment is active
 */
export function isPreviewDeploymentActive() {
  return !!getPreviewDeploymentSha();
}

export function copyPreviewDeploymentLink(sha: string) {
  const url = new URL(window.location.href);
  if (!sha) {
    return;
  }
  url.searchParams.set(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM, sha);
  navigator.clipboard.writeText(url.toString()).then(() => {
    alert('Preview URL copied to clipboard.');
  });
}

export function urlContainsPreviewSha(urlPath: string) {
  try {
    return new URL(urlPath, window.location.origin).searchParams.has(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  } catch (e) {
    return false;
  }
}
