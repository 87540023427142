import React from 'react';
import { useTheme } from '@chronosphereio/chrono-ui';

export interface ChronosphereBannerProps extends React.SVGProps<SVGSVGElement> {
  textColor?: string;
  title?: string;
}

export const ChronosphereBanner = (props: ChronosphereBannerProps) => {
  const { textColor, title = 'Chronosphere Logo', ...others } = props;
  const { theme } = useTheme();
  return (
    <svg viewBox="23 23 142 28" aria-labelledby="chronosphere-banner-title" {...others}>
      <title id="chronosphere-banner-title">{title}</title>
      <path
        fill={textColor ?? theme.palette.text.primary}
        d="M60.5 33c2.2 0 3.7 1.1 4.1 3H63c-.3-1.1-1.2-1.8-2.5-1.8-1.6 0-2.8 1.2-2.8 3.3 0 2.2 1.2 3.4 2.8 3.4 1.3 0 2.2-.6 2.5-1.8h1.6c-.5 1.8-1.9 3-4.1 3-2.5 0-4.3-1.8-4.3-4.6 0-2.7 1.8-4.5 4.3-4.5zM66.1 30h1.5v4.4c.6-.9 1.7-1.4 2.9-1.4 2 0 3.6 1.3 3.6 3.8V42h-1.5v-5c0-1.8-1-2.7-2.5-2.7s-2.6.9-2.6 2.9V42H66V30zM77.5 42H76v-8.9h1.5v1.4c.5-1 1.5-1.6 2.9-1.6v1.5H80c-1.4 0-2.5.6-2.5 2.7V42zM85.4 42.2c-2.5 0-4.4-1.8-4.4-4.6s2-4.6 4.5-4.6 4.5 1.8 4.5 4.6-2.1 4.6-4.6 4.6zm0-1.3c1.5 0 3.1-1.1 3.1-3.3 0-2.2-1.5-3.3-3-3.3-1.6 0-3 1.1-3 3.3 0 2.2 1.3 3.3 2.9 3.3zM98 37c0-1.8-1-2.7-2.5-2.7s-2.6.9-2.6 2.9V42h-1.5v-8.9h1.5v1.3c.6-.9 1.7-1.4 2.9-1.4 2.1 0 3.6 1.3 3.6 3.8V42H98v-5zM105.4 42.2c-2.5 0-4.4-1.8-4.4-4.6s2-4.6 4.5-4.6 4.5 1.8 4.5 4.6-2.1 4.6-4.6 4.6zm0-1.3c1.5 0 3.1-1.1 3.1-3.3 0-2.2-1.5-3.3-3-3.3-1.6 0-3 1.1-3 3.3 0 2.2 1.4 3.3 2.9 3.3zM114.8 42.2c-2.1 0-3.5-1.1-3.6-2.8h1.5c.1.9.8 1.5 2.1 1.5 1.2 0 1.8-.6 1.8-1.3 0-2-5.3-.8-5.3-4.1 0-1.4 1.3-2.5 3.3-2.5 2 0 3.3 1.1 3.4 2.8h-1.5c-.1-.9-.7-1.5-1.9-1.5-1.1 0-1.7.5-1.7 1.2 0 2.1 5.2 1 5.2 4.1 0 1.5-1.3 2.6-3.3 2.6zM124.4 33c2.4 0 4.2 1.8 4.2 4.6s-1.8 4.6-4.2 4.6c-1.6 0-2.8-.9-3.3-1.8v5.9h-1.5V33.1h1.5v1.6c.5-.9 1.6-1.7 3.3-1.7zm-.3 1.3c-1.6 0-3 1.2-3 3.3 0 2.1 1.4 3.3 3 3.3s3-1.2 3-3.3-1.4-3.3-3-3.3zM130.1 30h1.5v4.4c.6-.9 1.7-1.4 2.9-1.4 2 0 3.6 1.3 3.6 3.8V42h-1.5v-5c0-1.8-1-2.7-2.5-2.7s-2.6.9-2.6 2.9V42H130V30zM144 42.2c-2.5 0-4.4-1.8-4.4-4.6s1.8-4.6 4.4-4.6c2.6 0 4.3 1.8 4.3 4.3v.9h-7.1c.1 1.8 1.4 2.8 2.9 2.8 1.3 0 2.2-.7 2.5-1.6h1.6c-.6 1.5-2 2.8-4.2 2.8zm-2.9-5.3h5.6c0-1.7-1.3-2.7-2.8-2.7-1.4 0-2.6 1-2.8 2.7zM151.3 42h-1.5v-8.9h1.5v1.4c.5-1 1.5-1.6 2.9-1.6v1.5h-.4c-1.4 0-2.5.6-2.5 2.7V42zM159.1 42.2c-2.5 0-4.4-1.8-4.4-4.6s1.8-4.6 4.4-4.6c2.6 0 4.3 1.8 4.3 4.3v.9h-7.1c.1 1.8 1.4 2.8 2.9 2.8 1.3 0 2.2-.7 2.5-1.6h1.6c-.6 1.5-2 2.8-4.2 2.8zm-2.9-5.3h5.6c0-1.7-1.3-2.7-2.8-2.7-1.4 0-2.6 1-2.8 2.7z"
      />
      <defs>
        <path
          id="SVGID_1_"
          d="M46 42.1c-2.5 0-4.6-2-4.6-4.6s2-4.6 4.6-4.6c1.6 0 3.1.9 3.9 2.2-1.2-5.7-6.2-10-12.3-10-6.9 0-12.5 5.6-12.5 12.5s5.6 12.5 12.5 12.5c6.1 0 11.2-4.4 12.3-10.3-.8 1.4-2.2 2.3-3.9 2.3z"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <circle cx="37.6" cy="37.6" r="12.5" fill="#02aa69" />
        <circle cx="37.6" cy="37.6" r="12.5" fill="#02ab6a" />
        <circle cx="37.7" cy="37.6" r="12.4" fill="#02ac6a" />
        <circle cx="37.8" cy="37.6" r="12.4" fill="#02ad6b" />
        <circle cx="37.8" cy="37.6" r="12.3" fill="#02ae6c" />
        <circle cx="37.9" cy="37.6" r="12.2" fill="#02af6d" />
        <circle cx="37.9" cy="37.6" r="12.2" fill="#02b06d" />
        <circle cx="38" cy="37.6" r="12.1" fill="#02b16e" />
        <circle cx="38" cy="37.6" r="12.1" fill="#02b26f" />
        <circle cx="38.1" cy="37.6" r="12" fill="#02b36f" />
        <circle cx="38.2" cy="37.6" r="12" fill="#02b470" />
        <circle cx="38.2" cy="37.6" r="11.9" fill="#02b571" />
        <circle cx="38.3" cy="37.6" r="11.8" fill="#02b672" />
        <circle cx="38.3" cy="37.6" r="11.8" fill="#02b772" />
        <circle cx="38.4" cy="37.6" r="11.7" fill="#01b873" />
        <circle cx="38.4" cy="37.6" r="11.7" fill="#01b974" />
        <circle cx="38.5" cy="37.6" r="11.6" fill="#01ba74" />
        <circle cx="38.6" cy="37.6" r="11.6" fill="#01bb75" />
        <circle cx="38.6" cy="37.6" r="11.5" fill="#01bc76" />
        <circle cx="38.7" cy="37.6" r="11.4" fill="#01bd77" />
        <circle cx="38.7" cy="37.6" r="11.4" fill="#01be77" />
        <circle cx="38.8" cy="37.6" r="11.3" fill="#01bf78" />
        <circle cx="38.8" cy="37.6" r="11.3" fill="#01c079" />
        <circle cx="38.9" cy="37.6" r="11.2" fill="#01c179" />
        <circle cx="39" cy="37.6" r="11.2" fill="#01c27a" />
        <circle cx="39" cy="37.6" r="11.1" fill="#01c37b" />
        <circle fill="#01c47c" cx="39.1" cy="37.6" r="11" />
        <circle fill="#01c47c" cx="39.1" cy="37.6" r="11" />
        <circle cx="39.2" cy="37.6" r="10.9" fill="#01c57d" />
        <circle cx="39.2" cy="37.6" r="10.9" fill="#01c67e" />
        <circle cx="39.3" cy="37.6" r="10.8" fill="#01c77f" />
        <circle cx="39.4" cy="37.6" r="10.8" fill="#01c87f" />
        <circle cx="39.4" cy="37.6" r="10.7" fill="#01c980" />
        <circle cx="39.5" cy="37.6" r="10.6" fill="#01ca81" />
        <circle cx="39.5" cy="37.6" r="10.6" fill="#01cb81" />
        <circle cx="39.6" cy="37.6" r="10.5" fill="#01cc82" />
        <circle cx="39.6" cy="37.6" r="10.5" fill="#01cd83" />
        <circle cx="39.7" cy="37.6" r="10.4" fill="#01ce84" />
        <circle cx="39.8" cy="37.6" r="10.4" fill="#01cf84" />
        <circle cx="39.8" cy="37.6" r="10.3" fill="#01d085" />
        <circle cx="39.9" cy="37.6" r="10.2" fill="#00d186" />
        <circle cx="39.9" cy="37.6" r="10.2" fill="#00d286" />
        <circle cx="40" cy="37.6" r="10.1" fill="#00d387" />
        <circle cx="40" cy="37.6" r="10.1" fill="#00d488" />
        <circle cx="40.1" cy="37.6" r="10" fill="#00d589" />
        <circle cx="40.2" cy="37.6" r="10" fill="#00d689" />
        <circle cx="40.2" cy="37.6" r="9.9" fill="#00d78a" />
        <circle cx="40.3" cy="37.6" r="9.9" fill="#00d88b" />
        <circle cx="40.3" cy="37.6" r="9.8" fill="#00d98b" />
        <circle cx="40.4" cy="37.6" r="9.7" fill="#00da8c" />
        <circle cx="40.4" cy="37.6" r="9.7" fill="#00db8d" />
        <circle cx="40.5" cy="37.6" r="9.6" fill="#00dc8e" />
        <circle cx="40.5" cy="37.6" r="9.6" fill="#00dd8e" />
        <circle cx="40.6" cy="37.6" r="9.5" fill="#00de8f" />
        <path
          d="M46 42.1c-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6 1.6 0 3 .8 3.9 2.1-1.2-5.7-6.2-10-12.3-10-6.9 0-12.5 5.6-12.5 12.5S30.7 50 37.6 50c6.1 0 11.2-4.4 12.3-10.1-.8 1.4-2.3 2.2-3.9 2.2z"
          fill="none"
        />
      </g>
    </svg>
  );
};
