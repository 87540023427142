import React from 'react';
import { SnackbarKey } from 'notistack';
import { Box, Alert } from '@chronosphereio/chrono-ui';
import { AlertSeverity } from './types';

export interface SnackbarErrorContentProps {
  id: SnackbarKey;
  title: string;
  subtitle?: string;
  details?: string[];
  severity: AlertSeverity;
  closeSnackbar: (key: SnackbarKey) => void;
}

export const SnackbarErrorContent = React.forwardRef(function SnackbarErrorContent(
  { id, title, subtitle, details, severity, closeSnackbar, ...otherProps }: SnackbarErrorContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Alert
      ref={ref}
      {...otherProps}
      severity={severity}
      onClose={() => closeSnackbar(id)}
      aria-describedby="snackbar-err-content"
      sx={{ minWidth: 300 }}
      Header={<div id="snackbar-err-content">{title}</div>}
      MoreDetails={
        details ? (
          <>
            {details.map((msg, idx) => (
              <Box key={idx}>{msg}</Box>
            ))}
          </>
        ) : undefined
      }
    >
      {subtitle}
    </Alert>
  );
});
