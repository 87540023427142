import React, { useEffect, useState, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Alert, Box, Button } from '@chronosphereio/chrono-ui';
import SSOIcon from 'mdi-material-ui/CloudLock';
import { useLocation } from 'react-router-dom';
import { LoginForm } from './LoginForm';
import { useLoginMethods, InvalidCredentialsError } from './use-login-methods';
import {
  LoginFormSchema,
  LoginFormValues,
  CliLoginState,
  LOGIN_FROM_IDP_URL_PARAM,
  CLI_CALLBACK_URL_PARAM,
} from './login-model';
import { CliLoginAlert } from './CliLoginAlert';
import { LogoPane, LoadingPane } from '@/components';
import { useThrowAsyncError } from '@/utils';

/**
 * The view for logging into the system.
 */
function Login({ fromCli }: { fromCli?: boolean } = { fromCli: false }): JSX.Element {
  const { isLoading, loginWithSso, loginWithPassword } = useLoginMethods(fromCli);
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  };

  const throwError = useThrowAsyncError();

  // If SSO Login is the only type available or they initiated a login from the
  // IdP directly, just redirect automatically
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const autoRedirect =
    (loginWithSso !== undefined && loginWithPassword === undefined) ||
    (loginWithSso !== undefined && params.has(LOGIN_FROM_IDP_URL_PARAM));
  const tokenCallback = params.get(CLI_CALLBACK_URL_PARAM) ?? undefined;

  // for CLI login flow, pass token callback param through Okta state
  const oktaState: CliLoginState = useMemo(() => {
    return fromCli ? { tokenCallback } : {};
  }, [fromCli, tokenCallback]);

  const handleSubmit = async (values: LoginFormValues, helpers: FormikHelpers<LoginFormValues>) => {
    try {
      // This shouldn't happen since we conditionally render below, but throw in case
      if (loginWithPassword === undefined) {
        throw new Error('Password login is not supported');
      }

      setInvalidCredentials(false);
      await loginWithPassword(values, JSON.stringify(oktaState));
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        setInvalidCredentials(true);
      } else {
        return throwError(err as Error);
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const [isRedirecting, setIsRedirecting] = useState(false);
  const handleSsoClick = () => {
    // This shouldn't happen because of conditional rendering, but throw anyway
    if (loginWithSso === undefined) {
      throw new Error('SSO login is not supported');
    }

    try {
      setIsRedirecting(true);
      loginWithSso([], JSON.stringify(oktaState));
    } catch (err) {
      return throwError(err as Error);
    }
  };

  useEffect(() => {
    if (autoRedirect && isRedirecting === false) {
      setIsRedirecting(true);
      loginWithSso?.([], JSON.stringify(oktaState));
    }
  }, [oktaState, autoRedirect, loginWithSso, isRedirecting, setIsRedirecting]);

  // Page can get in one of three states: loading, redirecting to SSO, or ready
  // for user input
  let content: React.ReactNode;
  if (isLoading) {
    content = <LoadingPane text="Loading Login" />;
  } else if (isRedirecting || autoRedirect) {
    content = <LoadingPane text="Redirecting to Single Sign On" />;
  } else if (fromCli && !tokenCallback) {
    content = (
      <>
        <CliLoginAlert />
        <Alert severity="warning">
          Callback URL was not provided. Provide a callback URL in order for your script to receive the authentication
          token.
        </Alert>
      </>
    );
  } else {
    content = (
      <>
        {fromCli && <CliLoginAlert />}
        {loginWithSso !== undefined && (
          <Button StartIcon={<SSOIcon />} fullWidth onClick={handleSsoClick}>
            Log in with Single Sign On
          </Button>
        )}

        {loginWithSso !== undefined && loginWithPassword !== undefined && (
          <Box
            sx={(theme) => ({
              margin: theme.spacing(6, 0),
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              color: theme.palette.text.primary,
              textTransform: 'uppercase',

              '&::before, &::after': {
                content: '""',
                flex: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
              },

              '&::before': {
                marginRight: theme.spacing(2),
              },

              '&::after': {
                marginLeft: theme.spacing(2),
              },
            })}
          >
            or
          </Box>
        )}

        {loginWithPassword !== undefined && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={LoginFormSchema}
            validateOnBlur
            validateOnChange={false}
          >
            <LoginForm invalidCredentials={invalidCredentials} />
          </Formik>
        )}
      </>
    );
  }

  return <LogoPane>{content}</LogoPane>;
}

/**
 * BULK EDIT NOTE: Default exports are no longer recommended.
 * Use Named export on next refactor.
 * See http://go/cloud-ui-modules-brief for more info.
 */
// eslint-disable-next-line import/no-default-export
export default Login;
