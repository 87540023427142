import {
  AlertingRoutes,
  AuthRoutes,
  BaseRoutes,
  DashboardRoutes,
  ExploringRoutes,
  HelpRoutes,
  HomeRoutes,
  ManagingRoutes,
  ServicesRoutes,
  ShapingRoutes,
} from '@/model/Routes';

export const getAllRoutePaths = () => {
  const simpleRoutes = Object.values({ ...BaseRoutes, ...AuthRoutes });
  const routeObjects = Object.values({
    ...HomeRoutes,
    ...ServicesRoutes,
    ...DashboardRoutes,
    ...AlertingRoutes,
    ...ExploringRoutes,
    ...ShapingRoutes,
    ...ManagingRoutes,
    ...HelpRoutes,
  }).flatMap(({ route }) => route);

  return [...simpleRoutes, ...routeObjects];
};
