import { useHistory } from 'react-router-dom';
import { Alert } from '@chronosphereio/chrono-ui';
import { LogoPane } from '../components';
import { AlertButton } from '@/chrono-ui-todos';
import { BaseRoutes } from '@/model/Routes';
/**
 * View that the user is redirected to after a successful logout.
 */
function LogoutCallback() {
  const history = useHistory();
  const goToLogin = () => history.push(BaseRoutes.LOGIN);

  return (
    <LogoPane>
      <Alert severity="success" Action={<AlertButton onClick={goToLogin}>Login Again</AlertButton>}>
        You have been successfully logged out.
      </Alert>
    </LogoPane>
  );
}

/**
 * BULK EDIT NOTE: Default exports are no longer recommended.
 * Use Named export on next refactor.
 * See http://go/cloud-ui-modules-brief for more info.
 */
// eslint-disable-next-line import/no-default-export
export default LogoutCallback;
