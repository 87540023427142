import { object, string } from 'yup';
import { Location } from 'history';
import { POST_LOGIN_REDIRECT_URL_PARAM } from '@chronosphereio/core';

/**
 * Form values used during login.
 */
export interface LoginFormValues {
  email: string;
  password: string;
}

/**
 * Validation schema for the login form.
 */
export const LoginFormSchema = object<LoginFormValues>({
  email: string().required().email().label('Email'),
  password: string().required().label('Password'),
});

/**
 * Form values used to reset your password.
 */
export interface ResetPasswordFormValues {
  email: string;
}

/**
 * Validation schema for reset password form.
 */
export const ResetPasswordSchema = object<ResetPasswordFormValues>({
  email: string().required().email().label('Email'),
});

/**
 * Used on the login page to determine what URL (if any) to redirect to
 * after a successful login.
 */
export function getPostLoginRedirectUrl(location: Location) {
  const params = new URLSearchParams(location.search);
  return params.get(POST_LOGIN_REDIRECT_URL_PARAM) ?? undefined;
}

/**
 * Find the tenant in the hostname (or just return the hostname if not found)
 */
export function tenantFromHostname(hostname: string) {
  const idx = hostname.lastIndexOf('.chronosphere.io');
  return idx > 0 ? hostname.substring(0, idx) : hostname;
}

/**
 * Login info passed through Okta state for CLI login flow
 */
export interface CliLoginState {
  /**
   * host:port to which the auth token should be sent
   */
  tokenCallback?: string;
}

/**
 * Storage key used to keep track of where to send a user after a successful
 * login.
 */
export const POST_LOGIN_REDIRECT_STORAGE_KEY = 'CHRONOSPHERE_REDIRECT_TO';

/**
 * Parameter added to the URL when the user has initiated a login directly
 * from the IdP (i.e. Okta).
 */
export const LOGIN_FROM_IDP_URL_PARAM = 'fromLogin';

/**
 * For CLI login: the host:port to which the auth token should be sent
 */
export const CLI_CALLBACK_URL_PARAM = 'token_callback';

/**
 * For CLI login: if impersonate=viewer, auto-login as viewer
 */
export const CLI_IMPERSONATE_URL_PARAM = 'impersonate';
