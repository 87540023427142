import React from 'react';
import { IconButton, IconButtonProps } from '@chronosphereio/chrono-ui';
import CopyIcon from 'mdi-material-ui/ContentCopy';
import { useCopyToClipboard } from '@/utils/copy-to-clipboard';

export type TooltipClipboardButtonProps = Omit<IconButtonProps, 'onClick'> & {
  valueToCopy: string;
  innerSx?: IconButtonProps['sx'];
};

/**
 * Button that will copy the 'valueToCopy' prop's value to the clipboard when
 * clicked.
 */
export function CopyToClipboardButton({ valueToCopy, innerSx, ...props }: TooltipClipboardButtonProps) {
  const copy = useCopyToClipboard();

  return (
    <IconButton
      title="copy-to-clipboard"
      variant="secondary"
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await copy(valueToCopy);
      }}
      {...props}
    >
      <CopyIcon sx={innerSx} />
    </IconButton>
  );
}
