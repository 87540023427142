import { useMemo, ChangeEvent, useCallback, useRef } from 'react';
import { useField, FieldMetaProps, useFormikContext, FieldHookConfig, FieldInputProps, FieldHelperProps } from 'formik';
import {
  AutocompleteProps,
  AutocompleteValue,
  TextFieldProps,
  SelectProps,
  FormControlProps,
  OutlinedInputProps,
  FormHelperTextProps,
  InputLabelProps,
  CheckboxProps,
} from '@mui/material';
import { ButtonProps, ToggleButtonGroupProps } from '@chronosphereio/chrono-ui';
import { useDeepMemo } from '@chronosphereio/core';

// The return type of the base `useField` method from Formik
export type UseFieldProps<T> = [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>];

export type UseFieldRef<T> = React.MutableRefObject<UseFieldProps<T>>;

// Common MUI FormControl props returned by form state hooks
type UseFormControlProps = Pick<FormControlProps, 'error'>;

// Common InputLabel props returned by form state hooks
type UseInputLabelProps = Pick<InputLabelProps, 'htmlFor' | 'id'>;

// Common props returned by form state hooks or for custom controls
interface UseFormFieldProps<T> {
  formControl: UseFormControlProps;
  inputLabel: UseInputLabelProps;
  formHelperText: UseFormHelperTextProps;
  value: T;
  setValue: (value: T) => void;
}

/**
 * Props for MUI components returned by useInput hook.
 */
export interface UseInputProps<T> extends UseFormFieldProps<T> {
  input: Pick<OutlinedInputProps, 'id' | 'name' | 'value' | 'onChange' | 'onBlur'>;
}

/**
 * Returns a ref containing the return values of useField.
 * This is used to prevent re-rendering of components when Formik values change.
 * @param name Name of the field in Formik
 * @returns Ref containing the return values of useField
 */
export function useFieldRef<T>(name: string | FieldHookConfig<T>) {
  const field = useField<T>(name);
  const ref = useRef<UseFieldProps<T>>(field);
  ref.current = field;
  return ref;
}

/**
 * Memoized version of useField hook, used in conjunction with React.memo
 * @param name Name of the field in Formik
 * @returns Memoized version return values of useField
 */
export function useMemoizedField<T>(name: string | FieldHookConfig<T>): UseFieldProps<T> {
  const fieldRef = useFieldRef<T>(name);
  const [props, meta] = fieldRef.current;

  return useDeepMemo(() => {
    const [, , helper] = fieldRef.current;
    return [props, meta, helper];
  }, [props, meta]);
}

/**
 * Hook for getting Material UI FilledInput and related component props for a string form field in Formik.
 * @param name the name or path to the form field in Formik
 */
export function useInput<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseInputProps<T> {
  const [field, meta, helpers] = useField<T>(propsOrFieldName);
  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
    },
    input: {
      ...field,
      id: field.name,
    },
    formHelperText: useFormHelperText(meta, false),
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useInput, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik
 */
export function useMemoizedInput<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseInputProps<T> {
  const fieldRef = useFieldRef<T>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);
  return useDeepMemo(
    () => ({
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
      },
      input: {
        ...field,
        id: field.name,
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    }),
    [formHelperText, meta, field]
  );
}

/**
 * Props for MUI components from the useSelect hook.
 */
export interface UseSelectProps<T> extends UseFormFieldProps<T> {
  select: Pick<SelectProps<T>, 'id' | 'labelId' | 'name' | 'value' | 'onChange' | 'onBlur'>;
}

/**
 * Hook for getting MUI Select component and related props for a form field in Formik.
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useSelect<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseSelectProps<T> {
  const [field, meta, helpers] = useField<T>(propsOrFieldName);
  const labelId = `${field.name}-label`;

  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
      id: labelId,
    },
    select: {
      ...field,
      value: field.value ?? '',
      id: field.name,
      labelId,
    },
    formHelperText: useFormHelperText(meta, false),
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useSelect, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedSelect<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseSelectProps<T> {
  const fieldRef = useFieldRef<T>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(() => {
    const labelId = `${field.name}-label`;

    return {
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
        id: labelId,
      },
      select: {
        ...field,
        value: field.value ?? '',
        id: field.name,
        labelId,
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    };
  }, [meta, field, formHelperText]);
}

export interface UseButtonGroupSelectProps<T extends string | number> extends UseFormFieldProps<T> {
  toggleButtonGroup: Pick<ToggleButtonGroupProps<T>, 'id' | 'value' | 'onChange' | 'onBlur'>;
}

/**
 * Props for linking Formik to a ButtonGroupSelect.
 */
export function useToggleButtonGroup<T extends string | number = string>(
  propsOrFieldName: string | FieldHookConfig<T>
): UseButtonGroupSelectProps<T> {
  const [field, meta, helpers] = useField<T>(propsOrFieldName);

  const { setTouched } = helpers;
  const onBlur = useCallback(() => setTouched(true), [setTouched]);

  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
    },
    toggleButtonGroup: {
      ...field,
      id: field.name,
      onChange: (_, nextValue) => helpers.setValue(nextValue),
      onBlur,
    },
    formHelperText: useFormHelperText(meta, false),
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useToggleButtonGroup, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedToggleButtonGroup<T extends string | number = string>(
  propsOrFieldName: string | FieldHookConfig<T>
): UseButtonGroupSelectProps<T> {
  const fieldRef = useFieldRef<T>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(() => {
    return {
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
      },
      toggleButtonGroup: {
        ...field,
        id: field.name,
        onChange: (event, nextValue) => {
          const [, , { setValue }] = fieldRef.current;
          setValue(nextValue);
        },
        onBlur: () => {
          const [, , { setTouched }] = fieldRef.current;
          setTouched(true);
        },
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    };
  }, [meta, field, formHelperText]);
}

/**
 * Props for MUI components from the useAutocomplete hook.
 */
export interface UseAutocompleteProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> {
  autocomplete: Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'id' | 'value' | 'onBlur' | 'onChange'
  >;
  textField: Pick<TextFieldProps, 'error' | 'helperText'>;
  value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
  setValue: (value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) => void;
}

/**
 * Hook for getting MUI Autocomplete and related form component props for a form field in Formik.
 * @param name the name or path to the form field in Formik
 */
export function useAutocomplete<
  T,
  Multiple extends boolean = true,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>(
  propsOrFieldName: string | FieldHookConfig<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>
): UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  // Helper type so we don't have to keep spelling out all the generic args
  type ValueType = AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;

  const [field, meta, helpers] = useField<ValueType>(propsOrFieldName);

  return {
    autocomplete: {
      ...field,
      onChange: (e: ChangeEvent<unknown>, value: ValueType) => {
        helpers.setValue(value);
      },
      id: field.name,
    },
    textField: {
      error: hasError(meta),
      helperText: useFormHelperText(meta, false).children,
    },
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useAutocomplete, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedAutocomplete<
  T,
  Multiple extends boolean = true,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>(
  propsOrFieldName: string | FieldHookConfig<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>
): UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  // Helper type so we don't have to keep spelling out all the generic args
  type ValueType = AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
  const fieldRef = useFieldRef<ValueType>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const helperText = useFormHelperText(meta, false).children;

  return useDeepMemo(
    () => ({
      autocomplete: {
        ...field,
        onChange: (e: ChangeEvent<unknown>, value: ValueType) => {
          const [, , { setValue }] = fieldRef.current;
          setValue(value);
        },
        id: field.name,
      },
      textField: {
        error: hasError(meta),
        helperText,
      },
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    }),
    [helperText, meta, field]
  );
}

/*
 * Props for MUI components returned by useTextField hook.
 */
export interface UseTextFieldProps<T> {
  textField: Pick<TextFieldProps, 'id' | 'name' | 'value' | 'onChange' | 'onBlur' | 'error' | 'helperText'>;
  value: T;
  setValue: (value: T) => void;
}

/**
 * Hook for getting Material UI TextField props for a string form field in Formik.
 * @param name the name or path to the form field in Formik
 */
export function useTextField<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseTextFieldProps<T> {
  const [field, meta, helpers] = useField<T>(propsOrFieldName);
  return {
    textField: {
      ...field,
      error: hasError(meta),
      id: field.name,
      helperText: useFormHelperText(meta, false).children,
    },
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useTextField, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedTextField<T = string>(propsOrFieldName: string | FieldHookConfig<T>): UseTextFieldProps<T> {
  const fieldRef = useFieldRef<T>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const helperText = useFormHelperText(meta, false).children;

  return useDeepMemo(() => {
    return {
      textField: {
        ...field,
        error: hasError(meta),
        id: field.name,
        helperText,
      },
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    };
  }, [meta, field, helperText]);
}

/**
 * Props for MUI components from the useCheckbox hook.
 */
export interface useCheckboxProps<T> extends UseFormFieldProps<T> {
  checkbox: Pick<CheckboxProps, 'id' | 'checked' | 'onBlur' | 'onChange'>;
}

/**
 * Hook for getting MUI Checkbox and related form component props for a form field in Formik.
 * @param name the name or path to the form field in Formik
 */
export function useCheckbox(propsOrFieldName: string | FieldHookConfig<boolean>): useCheckboxProps<boolean> {
  const [field, meta, helpers] = useField<boolean>(propsOrFieldName);
  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
    },
    checkbox: {
      ...field,
      checked: field.value,
      onChange: (e: ChangeEvent<unknown>, value: boolean) => {
        helpers.setValue(value);
      },
      onBlur: (e: ChangeEvent<unknown>) => {
        field.onBlur(e);
      },
      id: field.name,
    },
    formHelperText: useFormHelperText(meta, false),
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useCheckbox, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedCheckbox(propsOrFieldName: string | FieldHookConfig<boolean>): useCheckboxProps<boolean> {
  const fieldRef = useFieldRef<boolean>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(
    () => ({
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
      },
      checkbox: {
        ...field,
        checked: field.value,
        onChange: (e: ChangeEvent<unknown>, value: boolean) => {
          const [, , { setValue }] = fieldRef.current;
          setValue(value);
        },
        onBlur: (e: ChangeEvent<unknown>) => {
          const [{ onBlur }] = fieldRef.current;
          onBlur(e);
        },
        id: field.name,
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    }),
    [formHelperText, meta, field]
  );
}

/**
 * Props for MUI components returned by useFileInput hook.
 */
export interface UseFileInputProps extends UseFormFieldProps<FileList | null> {
  input: Pick<React.InputHTMLAttributes<HTMLInputElement>, 'id' | 'name' | 'onChange' | 'onBlur'>;
}

/**
 * Hook for getting <input type="file" /> props and related MUI components from Formik.
 */
export function useFileInput(propsOrFieldName: string | FieldHookConfig<FileList | null>): UseFileInputProps {
  const [field, meta, helpers] = useField<FileList | null>(propsOrFieldName);
  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
    },
    // Can't pass 'value' prop to file inputs since they can't be controlled
    input: {
      id: field.name,
      name: field.name,
      onChange: (e) => {
        const list = e.target.files;
        helpers.setValue(list);
      },
      onBlur: () => {
        helpers.setTouched(true);
      },
    },
    formHelperText: useFormHelperText(meta, false),
    value: field.value,
    setValue: helpers.setValue,
  };
}

/**
 * Memoized version of useFileInput, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedFileInput(propsOrFieldName: string | FieldHookConfig<FileList | null>): UseFileInputProps {
  const fieldRef = useFieldRef<FileList | null>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(
    () => ({
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
      },
      // Can't pass 'value' prop to file inputs since they can't be controlled
      input: {
        id: field.name,
        name: field.name,
        onChange: (e) => {
          const [, , { setValue }] = fieldRef.current;
          const list = e.target.files;
          setValue(list);
        },
        onBlur: () => {
          const [, , { setTouched }] = fieldRef.current;
          setTouched(true);
        },
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    }),
    [formHelperText, meta, field]
  );
}

/**
 * Hook to get props for a MUI Button for submitting a form based on Formik form state.
 */
export function useSubmitButton(): Pick<ButtonProps, 'type' | 'disabled'> {
  const { isSubmitting } = useFormikContext();

  return {
    type: 'submit',
    disabled: isSubmitting === true,
  };
}

/**
 * Props for some array manipulation helpers and array state returned from the useArrayField hook.
 */
export interface UseArrayFieldProps<T> {
  value: T[];
  error: boolean;
  formHelperText: UseFormHelperTextProps;
  setValue: (value: T[]) => void;
  add: (item: T) => void;
  remove: (index: number) => void;
  replace: (index: number, item: T) => void;
}

/**
 * Hook to get props for array form state in Formik.
 */
export function useArrayField<T>(propsOrFieldName: string | FieldHookConfig<T[]>): UseArrayFieldProps<T> {
  const [field, meta, helpers] = useField<T[]>(propsOrFieldName);

  return {
    value: field.value,
    setValue: helpers.setValue,
    error: hasError(meta),
    formHelperText: useFormHelperText(meta, false),
    add(...items: T[]) {
      const newArray = [...field.value];
      newArray.push(...items);
      helpers.setValue(newArray);
    },
    remove(index: number) {
      const newArray = [...field.value];
      newArray.splice(index, 1);
      helpers.setValue(newArray);
    },
    replace(index: number, item: T) {
      const newArray = [...field.value];
      newArray.splice(index, 1, item);
      helpers.setValue(newArray);
    },
  };
}

/**
 * Memoized version of useArrayField, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedArrayField<T>(propsOrFieldName: string | FieldHookConfig<T[]>): UseArrayFieldProps<T> {
  const fieldRef = useFieldRef<T[]>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(
    () => ({
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
      error: hasError(meta),
      formHelperText,
      add(...items: T[]) {
        const [, , { setValue }] = fieldRef.current;
        const newArray = [...field.value];
        newArray.push(...items);
        setValue(newArray);
      },
      remove(index: number) {
        const [, , { setValue }] = fieldRef.current;
        const newArray = [...field.value];
        newArray.splice(index, 1);
        setValue(newArray);
      },
      replace(index: number, item: T) {
        const [, , { setValue }] = fieldRef.current;
        const newArray = [...field.value];
        newArray.splice(index, 1, item);
        setValue(newArray);
      },
    }),
    [formHelperText, meta, field]
  );
}

export interface ListInputProps<T> {
  id: string;
  name: string;
  value: T[];
  onAdd: (...items: T[]) => void;
  onRemove: (index: number) => void;
  onReplace: (index: number, item: T) => void;
}

export interface UseListInputProps<T> extends UseFormFieldProps<T[]> {
  listInput: ListInputProps<T>;
}

/**
 * Get properties for a generic list input control that manipulates a list of things.
 */
export function useListInput<T>(propsOrFieldName: string | FieldHookConfig<T[]>): UseListInputProps<T> {
  const [field, meta, helpers] = useField<T[]>(propsOrFieldName);

  const { value } = field;
  const { setValue } = helpers;

  // Callbacks for manipulating the list
  const onAdd = useCallback(
    (...items: T[]) => {
      const next = [...value];
      next.push(...items);
      setValue(next);
    },
    [value, setValue]
  );
  const onRemove = useCallback(
    (index: number) => {
      const next = [...value];
      next.splice(index, 1);
      setValue(next, true);
    },
    [value, setValue]
  );
  const onReplace = useCallback(
    (index: number, item: T) => {
      const next = [...value];
      next.splice(index, 1, item);
      setValue(next);
    },
    [value, setValue]
  );

  return {
    formControl: {
      error: hasError(meta),
    },
    inputLabel: {
      htmlFor: field.name,
    },
    listInput: {
      id: field.name,
      name: field.name,
      value,
      onAdd,
      onRemove,
      onReplace,
    },
    formHelperText: useFormHelperText(meta, false),
    value,
    setValue,
  };
}

/**
 * Memoized version of useListInput, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedListInput<T>(propsOrFieldName: string | FieldHookConfig<T[]>): UseListInputProps<T> {
  const fieldRef = useFieldRef<T[]>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(
    () => ({
      formControl: {
        error: hasError(meta),
      },
      inputLabel: {
        htmlFor: field.name,
      },
      listInput: {
        id: field.name,
        name: field.name,
        value: field.value,
        onAdd: (...items: T[]) => {
          const [, , { setValue }] = fieldRef.current;
          const next = [...field.value];
          next.push(...items);
          setValue(next);
        },
        onRemove: (index: number) => {
          const [, , { setValue }] = fieldRef.current;
          const next = [...field.value];
          next.splice(index, 1);
          setValue(next);
        },
        onReplace: (index: number, item: T) => {
          const [, , { setValue }] = fieldRef.current;
          const next = [...field.value];
          next.splice(index, 1, item);
          setValue(next);
        },
      },
      formHelperText,
      value: field.value,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
    }),
    [formHelperText, meta, field]
  );
}

export interface UseSetFieldProps {
  value: Set<string>;
  error: boolean;
  formHelperText: UseFormHelperTextProps;
  setValue: (value: Set<string>) => void;
  update: (item: string) => void;
  addAll: (items: string[]) => void;
  removeAll: () => void;
}

/**
 * Hook to get props for set form state in Formik.
 * @param name the name or path to the form field in Formik
 */

export function useSetField(propsOrFieldName: string | FieldHookConfig<Set<string>>): UseSetFieldProps {
  const [field, meta, helpers] = useField<Set<string>>(propsOrFieldName);

  return {
    value: field.value,
    error: hasError(meta),
    formHelperText: useFormHelperText(meta, false),
    setValue: helpers.setValue,
    update(item: string) {
      const newSet = new Set([...field.value]);
      if (newSet.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }
      helpers.setValue(newSet);
    },
    addAll(items: string[]) {
      helpers.setValue(new Set(items));
    },
    removeAll() {
      helpers.setValue(new Set([]));
    },
  };
}

/**
 * Memoized version of useSetField, used in conjunction with React.memo
 * @param propsOrFieldName the name or path to the form field in Formik or a subset
 * of props you would pass to Formik Field component
 */
export function useMemoizedSetField(propsOrFieldName: string | FieldHookConfig<Set<string>>): UseSetFieldProps {
  const fieldRef = useFieldRef<Set<string>>(propsOrFieldName);
  const [field, meta] = fieldRef.current;
  const formHelperText = useFormHelperText(meta, false);

  return useDeepMemo(
    () => ({
      value: field.value,
      error: hasError(meta),
      formHelperText,
      setValue: (params) => {
        const [, , { setValue }] = fieldRef.current;
        return setValue(params);
      },
      update(item: string) {
        const [, , { setValue }] = fieldRef.current;
        const newSet = new Set([...field.value]);
        if (newSet.has(item)) {
          newSet.delete(item);
        } else {
          newSet.add(item);
        }
        setValue(newSet);
      },
      addAll(items: string[]) {
        const [, , { setValue }] = fieldRef.current;
        setValue(new Set(items));
      },
      removeAll() {
        const [, , { setValue }] = fieldRef.current;
        setValue(new Set([]));
      },
    }),
    []
  );
}

type UseFormHelperTextProps = Pick<FormHelperTextProps, 'children'>;

/**
 * Gets MUI FormHelperText props from Formik meta state.
 */
function useFormHelperText<T>(meta: FieldMetaProps<T>, flatten: boolean): UseFormHelperTextProps {
  return useMemo(() => {
    if (hasError(meta) === false) {
      return { children: undefined };
    }

    // Since meta.error could be an array/object, only return an error message when not flattening if
    // meta.error is a string
    if (flatten === false) {
      return {
        children: typeof meta.error === 'string' ? meta.error : undefined,
      };
    }

    throw new Error('TODO: Support flattening nested formik errors');
  }, [meta, flatten]);
}

/**
 * Returns true if a form field should have error state based on its Formik meta state.
 */
function hasError<T>(meta: FieldMetaProps<T>): boolean {
  return meta.touched === true && meta.error !== undefined;
}
