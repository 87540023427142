import React, { ErrorInfo } from 'react';
import { FeatureFlagContext } from '@chronosphereio/core';
import { logError, FallbackComponentProps } from './error-utils';
import { ErrorDetails, getErrorDetails } from '@/model/errors';

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
  FallbackComponent?: React.ComponentType<FallbackComponentProps>;
  source?: string;
}

export interface ErrorBoundaryState {
  errorDetails?: ErrorDetails;
}

/**
 * Error boundary for handling uncaught errors in React components
 */
export class ErrorBoundary<T extends ErrorBoundaryProps> extends React.Component<T, ErrorBoundaryState> {
  static contextType = FeatureFlagContext;

  declare context: React.ContextType<typeof FeatureFlagContext>;

  constructor(props: T) {
    super(props);
    this.state = { errorDetails: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { errorDetails: getErrorDetails(error) };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const features = this.context.featureFlags;
    logError(error, features.clientSideTracing, info, this.props.source);
  }

  reset = () => {
    this.setState({ errorDetails: undefined });
  };

  render() {
    const FallbackComponent = this.props.FallbackComponent as React.ComponentType<FallbackComponentProps>;
    return this.state.errorDetails ? (
      FallbackComponent ? (
        <FallbackComponent errorDetails={this.state.errorDetails} onReset={this.reset} />
      ) : null
    ) : (
      this.props.children
    );
  }
}
