import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@chronosphereio/core';
import { LoadingPane } from '@/components';
import { useFetch, useThrowAsyncError } from '@/utils';
import { AuthRoutes } from '@/model/Routes';

/**
 * View that initiates a Logout.
 */
export function Logout() {
  const { config, oktaClient } = useAuth();
  const history = useHistory();

  // Always logout locally first
  const [{ data, error }] = useFetch('/auth/logout');

  if (error !== undefined) {
    throw error;
  }

  const throwError = useThrowAsyncError();

  useEffect(() => {
    async function doLogout() {
      if (data === undefined || config === undefined) return;

      if (config.type === 'okta' && oktaClient !== undefined) {
        // Send them to Okta to logout
        try {
          await oktaClient.signOut();
        } catch (err) {
          return throwError(err);
        }
        return;
      }

      if (config.type === 'fakeauth') {
        // For fake auth, just redirect to the callback manually
        history.push(AuthRoutes.LOGOUT_CALLBACK);
        return;
      }
    }

    doLogout();
  }, [data, config, oktaClient, history, throwError]);

  return <LoadingPane text="Logging you out" />;
}
