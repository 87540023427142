import { object, string, StringSchema } from 'yup';
import { AppError } from '@chronosphereio/core';

/**
 * URL query string param that contains an invite via email.
 */
export const INVITE_SEARCH_PARAM = 'invite';

/**
 * Parsed invite data from the query string when invited via email.
 */
export interface InviteJson {
  token: string;
  tenant?: {
    name: string;
  };
  email: string;
  expiresAt: string;
}

/**
 * Path used to call the server and accept the invite.
 */
export const INVITE_REQUEST_URL = '/auth/invite';

/**
 * POST data expected by the INVITE_REQUEST_URL endpoint.
 */
export interface InviteRequestData {
  email: string;
  password: string;
  name: string;
  invite: string;
}

/**
 * Form values when signing up for a new account.
 */
export interface SignupFormValues {
  email: string;
  name: string;
  company: string;
  password: string;
  confirmPassword: string;
}

/**
 * Validation schema for the signup form.
 */
export const SignupFormSchema = object<SignupFormValues>({
  email: string().required().label('Email'),
  name: string()
    .required()
    .label('Full Name')
    // Needs to have at least first and last with a space in between
    .matches(/^\w+ \w+/i, 'Please provide your full name'),
  company: string().required().label('Company'),
  password: string().required().label('Password'),
  confirmPassword: string()
    .required()
    .label('Confirm Password')
    .when('password', (password: string, confirmSchema: StringSchema) => {
      if (password !== '') {
        return confirmSchema.oneOf([password], 'The passwords do not match');
      }
      return confirmSchema;
    }),
});

/**
 * Error thrown when a user tries to use an expired invite.
 */
export class InviteExpiredError extends AppError {
  constructor() {
    super({ title: 'Signup invite has expired' });
    Object.setPrototypeOf(this, InviteExpiredError.prototype);
  }
}

/**
 * Error thrown when an invite code is missing from the URL.
 */
export class InviteMissingError extends AppError {
  constructor() {
    super({ title: 'Invite code is missing from the URL' });
    Object.setPrototypeOf(this, InviteMissingError.prototype);
  }
}
