import React, { useContext, useMemo, useCallback } from 'react';
import {
  SnackbarProvider as NotistackProvider,
  ProviderContext as NotistackContext,
  useSnackbar as useNotistack,
  SnackbarMessage,
  SnackbarKey,
} from 'notistack';
import { AppErrorSeverity } from '@chronosphereio/core';
import { AlertSeverity } from './snackbar/types';
import { SnackbarContent } from './snackbar/SnackbarContent';
import { SnackbarErrorContent } from './snackbar/SnackbarErrorContent';
import { getErrorDetails, ErrorDetailsOptions } from '@/model/errors';

type SnackbarOptions = {
  preventDuplicate?: boolean;
};

export interface SnackbarContextType {
  errorSnackbar: (message: SnackbarMessage, options?: SnackbarOptions) => SnackbarKey;
  infoSnackbar: (message: SnackbarMessage, options?: SnackbarOptions) => SnackbarKey;
  warningSnackbar: (message: SnackbarMessage, options?: SnackbarOptions) => SnackbarKey;
  successSnackbar: (message: SnackbarMessage, options?: SnackbarOptions) => SnackbarKey;
  exceptionSnackbar: (error: unknown, message: string | ErrorDetailsOptions, options?: SnackbarOptions) => SnackbarKey;
  closeSnackbar: NotistackContext['closeSnackbar'];
}

export const SnackbarContext = React.createContext<SnackbarContextType | undefined>(undefined);

/**
 * Wrapper for the 'notistack` snackbar provider that provides some convenience
 * methods for showing snackbars.
 */
export function SnackbarProvider(props: { children: React.ReactNode }) {
  return (
    <NotistackProvider>
      <SnackbarProviderInner>{props.children}</SnackbarProviderInner>
    </NotistackProvider>
  );
}

const ErrorToAlertSeverity: Record<AppErrorSeverity, AlertSeverity> = {
  info: 'info',
  warning: 'warning',
  error: 'error',
};

// Actual provider component since we need access to the Notistack context
function SnackbarProviderInner(props: { children: React.ReactNode }) {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  const successSnackbar: SnackbarContextType['successSnackbar'] = useCallback(
    (message, options) => {
      return enqueueSnackbar(message, {
        preventDuplicate: options?.preventDuplicate ?? true,
        content: (_, message) => <SnackbarContent severity="success" message={message} />,
      });
    },
    [enqueueSnackbar]
  );

  const infoSnackbar: SnackbarContextType['infoSnackbar'] = useCallback(
    (message, options) => {
      return enqueueSnackbar(message, {
        preventDuplicate: options?.preventDuplicate ?? true,
        content: (_, message) => <SnackbarContent severity="info" message={message} />,
      });
    },
    [enqueueSnackbar]
  );

  const warningSnackbar: SnackbarContextType['warningSnackbar'] = useCallback(
    (message, options) => {
      return enqueueSnackbar(message, {
        preventDuplicate: options?.preventDuplicate ?? true,
        content: (_, message) => <SnackbarContent severity="warning" message={message} />,
      });
    },
    [enqueueSnackbar]
  );

  const errorSnackbar: SnackbarContextType['errorSnackbar'] = useCallback(
    (message, options) => {
      return enqueueSnackbar(message, {
        preventDuplicate: options?.preventDuplicate ?? true,
        content: (_, message) => <SnackbarContent severity="error" message={message} />,
      });
    },
    [enqueueSnackbar]
  );

  const exceptionSnackbar: SnackbarContextType['exceptionSnackbar'] = useCallback(
    (error, message, options) => {
      const errorDetails = getErrorDetails(
        error as Error,
        typeof message === 'string' ? { severity: 'error', title: message } : message
      );
      return enqueueSnackbar(errorDetails.title, {
        persist: true,
        preventDuplicate: options?.preventDuplicate ?? true,
        content: (key) => (
          <SnackbarErrorContent
            id={key}
            severity={ErrorToAlertSeverity[errorDetails.severity]}
            title={errorDetails.title}
            subtitle={errorDetails.message}
            details={errorDetails.serverMessages}
            closeSnackbar={closeSnackbar}
          />
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const context: SnackbarContextType = useMemo(
    () => ({
      errorSnackbar,
      infoSnackbar,
      warningSnackbar,
      successSnackbar,
      exceptionSnackbar,
      closeSnackbar,
    }),
    [errorSnackbar, infoSnackbar, warningSnackbar, successSnackbar, exceptionSnackbar, closeSnackbar]
  );

  return <SnackbarContext.Provider value={context}>{props.children}</SnackbarContext.Provider>;
}

/**
 * Gets the SnackbarContext and throws if the provider is missing.
 */
export function useSnackbar(): SnackbarContextType {
  const ctx = useContext(SnackbarContext);
  if (ctx === undefined) {
    throw new Error('No SnackbarContext found. Did you forget the provider?');
  }
  return ctx;
}
