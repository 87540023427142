import React from 'react';
// FIXME: Deprecated usage of Box [sc-73820]
// eslint-disable-next-line no-restricted-imports
import { Box, BoxProps, CircularProgress, Typography } from '@mui/material';
import { useId } from '@chronosphereio/viz-framework';
import { combineSx } from '../styles';

export interface LoadingPaneProps extends BoxProps {
  text?: string;
}

/**
 * Renders a full width/height div with a centered loading indicator and specified text.
 */
export function LoadingPane(props: LoadingPaneProps) {
  const { text = 'Loading', sx, id, ...others } = props;

  const textComponentId = useId(id !== undefined ? `${id}-text` : 'loading-pane-text');

  return (
    <Box
      {...others}
      sx={combineSx(
        {
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
        },
        sx
      )}
    >
      <CircularProgress color="primary" aria-labelledby={textComponentId} />
      <Typography color="primary" id={textComponentId}>
        {text}
      </Typography>
    </Box>
  );
}
