import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getBootFlag, BootFlags, logError } from '@chronosphereio/core';
import { trackDoorbellInitializationFailed } from './monitoring/monitoring';

export interface FeedbackModal {
  /**
   * Shows the feedback modal.
   */
  show(): void;
}

// Boot flag value returned when doorbell is disabled
const DOORBELL_DISABLED_VALUE = 'disabled';

/**
 * Returns an object for showing a user feedback modal to the user. Will return `undefined` if the modal is not
 * available (which may be because feedback is not enabled in a particular environment).
 */
export function useFeedbackModal(): FeedbackModal | undefined {
  const isDoorbellInitialized = useInitializeDoorbell();

  const show: FeedbackModal['show'] = useCallback(() => {
    const doorbell = window.doorbell;
    if (doorbell === undefined) return;

    try {
      // Set some properties and refresh the Doorbell markup before showing
      doorbell.setProperty('subdomain', window.location.host.split('.')[0]);
      doorbell.setProperty('pathname', `${window.location.pathname}${window.location.search}`);
      doorbell.refresh();
      doorbell.show();
    } catch (err) {
      logError(err);
    }
  }, []);

  // Return the modal object once Doorbell is ready to go
  const modal = useMemo(() => ({ show }), [show]);
  return isDoorbellInitialized ? modal : undefined;
}

export interface FeedbackModalUserData {
  email: string;
  isAdmin: boolean;
}

export async function submitFeedback(
  message: string,
  options?: {
    tags?: string[];
  }
) {
  const { tags } = options ?? {};
  const doorbell = window.doorbell;
  const doorbellOpts = window.doorbellOptions;

  return new Promise((resolve, reject) => {
    if (doorbell && doorbellOpts?.email) {
      const onSuccess = () => {
        resolve(undefined);
      };
      const onFailure = () => {
        reject(new Error('Failed to send feedback'));
      };
      if (tags) {
        doorbell.setOption('tags', tags);
      }
      doorbell.send(message, doorbellOpts?.email, onSuccess, onFailure);
      // Need to refresh values after sending feedback
      // https://doorbell.io/docs/javascript
      doorbell.refresh();
    } else {
      resolve(undefined);
    }
  });
}

/**
 * NOTE: Stop, you probably don't need to use this. It's handled for you already in Cloud in the `AnalyticsProvider`.
 *
 * Returns a function for adding additional data about the user to the FeedbackModal once they've logged in.
 */
export function useConfigureFeedbackModalUser() {
  const isDoorbellInitialized = useInitializeDoorbell();

  const setAdditionalUserContext = useCallback((userData: FeedbackModalUserData) => {
    const doorbell = window.doorbell;
    if (doorbell === undefined) return;

    try {
      doorbell.setOption('email', userData.email);
      doorbell.setProperty('roles', [userData.isAdmin ? 'sysAdmin' : 'user']);
    } catch (err) {
      logError(err);
    }
  }, []);

  // Return the modal object once Doorbell is ready to go
  const modal = useMemo(() => ({ setAdditionalUserContext }), [setAdditionalUserContext]);
  return isDoorbellInitialized ? modal : undefined;
}

// Hook with the common logic to initialize the Doorbell API
function useInitializeDoorbell() {
  const doorbellProject = getBootFlag(BootFlags.DOORBELL_MODE);

  // We want Doorbell to load once for the app and then never load again (since it relies on global window object
  // properties for its options), so use a static queryKey and set cache/stale times to infinity
  const init = useQuery({
    queryKey: ['InitializeDoorbell'],
    queryFn: async () => {
      try {
        const config = availableProjects[doorbellProject];
        if (config === undefined) {
          throw new Error(`Unknown Doorbell project '${doorbellProject}'`);
        }

        await appendDoorbellScript(doorbellProject, config);
        return true;
      } catch (err) {
        logError(err);
        trackDoorbellInitializationFailed();
        return false;
      }
    },
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    enabled: doorbellProject !== DOORBELL_DISABLED_VALUE,
  });

  return init.data ?? false;
}

// The doorbell options that are unique per project
type DoorbellProjectConfig = Pick<DoorbellOptions, 'id' | 'appKey'>;

// These are the projects setup in Doorbell that are available. The string key here maps to a boot flag value so we
// know which configuration to use based on that flag.
const availableProjects: Record<string, DoorbellProjectConfig | undefined> = {
  internal: {
    id: '12476',
    appKey: 'm963LG395Y2bd7TQ1YD30wddWNSXGrFOMgCuXnIOGF7quu8tyKA8WhTVb1geSfr6',
  },
  external: {
    id: '12921',
    appKey: '6tgSBNIejmphRVGpDYjuMj7mjPlzsW11B4tj2FDKATLA2wbv2C5yK64tJkrb4X9q',
  },
};

declare global {
  interface Window {
    // Doorbell expects at least some of its options to be on the global window object before it's initialized
    doorbellOptions?: DoorbellOptions & DoorbellCallbacks;

    // The Doorbell API is available on the global window object once it's initialized
    doorbell?: DoorbellApi;
  }
}

// DoorbellOptions, DoorbellCallbacks, and DoorbellApi are all taken from https://doorbell.io/docs/javascript
interface DoorbellOptions {
  id: string;
  language?: string;
  appKey: string;
  windowLoaded?: boolean;
  hideEmail?: boolean;
  email?: string;
  properties?: Record<string, unknown>;
  hideButton?: boolean;
  tags?: string[];
}

interface DoorbellCallbacks {
  // called when the SDK has loaded and is about to initialize
  onLoad?: () => void;
  // called when the SDK has initialized (all elements added to the HTML)
  onInitialized?: () => void;
  // called when the modal is about to be shown
  onShow?: () => void;
  // called once the modal has been shown
  onShown?: () => void;
  // called when the modal is about to be hidden
  onHide?: () => void;
  // called once the modal has been hidden
  onHidden?: () => void;
  // called when the feedback is about to be sent
  // Return false to stop the request from happening
  onSubmit?: () => void;
  // called when the feedback is sent successfully
  onSuccess?: (message: string) => void;
  // called when the feedback fails to be sent
  onError?: (
    code: 'missing-email' | 'missing-message' | 'message-too-short' | 'invalid-email',
    message: string
  ) => void;
  // called when a screenshot is about to be generated. Returning false cancels it
  onGenerateScreenshot?: () => void;
  // callback when the screenshot is generated
  onScreenshotGenerated?: (screenshotDataURI: string) => void;
}

interface DoorbellApi {
  show(): void;
  hide(): void;
  remove(): void;
  getProperty(name: string): unknown;
  setProperty(name: string, value: unknown): void;
  getOption<T extends OptionKeys>(option: T): DoorbellOptions[T];
  setOption<T extends OptionKeys>(option: T, value: DoorbellOptions[T]): void;
  send(message: string, email: string, onSuccess: () => void, onFailure: (err: unknown) => void): void;
  refresh(): void;
}

type OptionKeys = Exclude<keyof DoorbellOptions, 'properties'>;

const SCRIPT_TAG_ID_SUFFIX = 'DoorbellScript';

function appendDoorbellScript(scriptId: string, config: DoorbellProjectConfig): Promise<void> {
  return new Promise((resolve) => {
    const scriptTagId = `${scriptId}${SCRIPT_TAG_ID_SUFFIX}`;
    const hasScript = document.getElementById(scriptTagId) !== null;

    if (hasScript) {
      throw new Error('Doorbell script was not initialized correctly');
    }

    window.doorbellOptions = {
      hideEmail: false,
      hideButton: true,
      language: 'en',
      windowLoaded: true,
      // Resolve the Promise once Doorbell has been initialized and is ready to go
      onInitialized: resolve,
      ...config,
    };

    const script = document.createElement('script');
    script.id = scriptTagId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.doorbell.io/button/' + window.doorbellOptions['id'] + '?t=' + new Date().getTime();

    // Ensure the text is always black. The modal is always white, but dark mode will set the color to white
    const style = document.createElement('style');
    style.innerHTML = `
    #doorbell {
      color: black;
    }
  `;
    document.head.appendChild(style);
    document.body.appendChild(script);
  });
}
