// comps/error-utils
import { ErrorInfo, useRef, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AppError, FetchError, logError as logException, getErrorSeverityLevel } from '@chronosphereio/core';
import { trackPageRenderErrors, trackRestApiError, trackCaughtError } from '@/utils';
import { ErrorDetails } from '@/model';
import { dispatchErrorEvent } from '@/utils/tracing';

/**
 * Call the onReset handler if URL path/hash/search changes
 */
export function useResetOnLocationChange(onReset: () => void) {
  const location = useLocation();
  const initialLocation = useRef(location);

  useEffect(() => {
    if (
      location.pathname !== initialLocation.current.pathname ||
      location.hash !== initialLocation.current.hash ||
      location.search !== initialLocation.current.search
    ) {
      onReset();
    }
  }, [location.pathname, location.hash, location.search, onReset]);
}

/**
 * Props that will be provided to the fallback component.
 */
export interface FallbackComponentProps {
  errorDetails: ErrorDetails;
  icon?: ReactNode;
  onReset: () => void;
}

/**
 * Log error to Sentry, tracing, and prom metrics
 */
export function logError(error: Error, tracingEnabled: boolean, errorInfo?: ErrorInfo, errorSource?: string) {
  const errorToLog = error instanceof AppError && error.rootCause ? error.rootCause : error;

  logException(
    errorToLog,
    errorInfo && errorInfo.componentStack
      ? { contexts: { react: { componentStack: errorInfo.componentStack } } }
      : undefined
  );

  // Tracing
  if (tracingEnabled) {
    dispatchErrorEvent(errorToLog, errorInfo);
  }

  // Prom metrics
  if (error instanceof FetchError) {
    // fetch 4xx/5xx response
    trackRestApiError({ error_source: errorSource ?? 'global', error_type: `HTTP ${error.response.status}` });
  } else if (error instanceof AppError) {
    trackCaughtError({ error_source: errorSource ?? 'global', error_type: error.title });
  } else {
    trackPageRenderErrors({ name: errorToLog.name, level: getErrorSeverityLevel(error) });
  }

  // Filter out warning-level errors from console because they get captured redundantly by Sentry console integration.
  // (we might want to remove this console log altogether). These are things like NotAuth'd, NotFound, NotOnline, etc.,
  // see function for details:
  if (getErrorSeverityLevel(error) !== 'warning') {
    // Console log
    console.error(errorToLog, errorInfo);
  }
}
