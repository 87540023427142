import copy from 'clipboard-copy';
import { useSnackbar } from '@/context/Snackbar';

export const useCopyToClipboard = () => {
  const { infoSnackbar, errorSnackbar } = useSnackbar();

  const copyToClipboard = async (value: string, message?: string) => {
    setTimeout(async () => {
      try {
        await copy(value);
        infoSnackbar(message ?? 'Copied');
      } catch (e) {
        errorSnackbar('Copy to clipboard failed');
      }
    }, 0);
  };

  return copyToClipboard;
};
