import { useState } from 'react';
import { Modal, ModalProps, ModalSize } from '@chronosphereio/chrono-ui';
import constate from 'constate';

type ModalState = {
  title: string;
  children: React.ReactNode;
  onConfirm?: () => void;
  primaryButtonText?: string;
  size?: ModalSize;
} & Partial<ModalProps>;

type ConfirmModalProps = {
  message: string;
  title?: string;
  onConfirmText?: string;
};

function useModalInternal() {
  const [modal, setModal] = useState<null | ModalState>(null);
  const openModal = (options: ModalState) => {
    setModal(options);
  };
  const closeModal = () => {
    setModal(null);
  };

  /**
   * Opens a modal with a confirm button and a cancel button. Returns a promise that resolves to true if the confirm
   */
  const confirmModal = (args: ConfirmModalProps) => {
    return new Promise((resolve) => {
      openModal({
        title: args.title ?? 'Confirm',
        children: args.message,
        primaryButtonText: args.onConfirmText ?? 'Confirm',
        onClose: () => {
          resolve(false);
          closeModal();
        },
        onConfirm: () => {
          resolve(true);
          closeModal();
        },
      });
    });
  };

  return {
    openModal,
    closeModal,
    confirmModal,
    modal,
  };
}

const [ModalProviderInternal, useModal] = constate(useModalInternal);

function ModalInternal() {
  const { modal, closeModal } = useModal();

  if (!modal) {
    return null;
  }

  return (
    <Modal
      size={modal.size ?? 'large'}
      onClose={closeModal}
      onPrimaryButtonClick={modal.onConfirm}
      primaryButtonText={modal.primaryButtonText || 'Confirm'}
      {...modal}
      title={modal.title}
      isOpen={modal !== null}
    >
      {modal.children}
    </Modal>
  );
}

type ModalProviderProps = {
  children: React.ReactNode;
};

export function ModalProvider(props: ModalProviderProps) {
  return (
    <ModalProviderInternal>
      <ModalInternal />
      {props.children}
    </ModalProviderInternal>
  );
}

export { useModal };
