import React from 'react';
import { Paper } from '@mui/material';
import { Box } from '@chronosphereio/chrono-ui';
import { ChronosphereBanner } from '../styles';
import { Link } from './Link';
import { RouteConstants } from '@/model/Routes';

export interface LogoPaneProps {
  children?: React.ReactNode;
}

/**
 * Template component that shows a full width/height pane with our logo and a
 * centered Paper below it.
 */
export function LogoPane(props: LogoPaneProps) {
  const { children } = props;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link to={RouteConstants.HOME} sx={{ width: 300 }}>
        <ChronosphereBanner />
      </Link>

      <Paper
        sx={(theme) => ({
          width: '100%',
          padding: theme.spacing(4, 2),

          [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
          },
        })}
      >
        {children}
      </Paper>
    </Box>
  );
}
