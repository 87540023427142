import {
  CombinedError,
  AppError,
  AppErrorSeverity,
  isNotAuthenticatedError,
  isOfflineError,
  isNotFoundError,
} from '@chronosphereio/core';
import ErrorIcon from 'mdi-material-ui/MagnifyRemoveOutline';
import OfflineIcon from 'mdi-material-ui/WifiOff';
import { Theme } from '@mui/material';

export interface ErrorDetails {
  severity: AppErrorSeverity;
  title: string;
  message?: string;
  serverMessages?: string[];
  link?: { url: string; label: string };
  icon?: typeof ErrorIcon;
  headerText?: string;
}

export function getServerErrorMessages(error: Error): string[] | undefined {
  if (error instanceof CombinedError && error.graphQLErrors.length > 0) {
    const errorMessages: string[] = [];
    for (const graphqlError of error.graphQLErrors) {
      errorMessages.push(graphqlError.message);
    }
    return errorMessages;
  } else return undefined;
}

export type ErrorDetailsOptions = Pick<ErrorDetails, 'severity' | 'title' | 'message'>;

/**
 * Derive info used for rendering errors from the given error object
 */
export function getErrorDetails(error: Error, options?: ErrorDetailsOptions): ErrorDetails {
  if (isNotAuthenticatedError(error)) {
    return {
      icon: ErrorIcon,
      severity: 'error',
      title: 'You are no longer logged in',
      message: 'Refresh your browser and contact Chronosphere support if the issue persists',
      link:
        error instanceof AppError
          ? undefined
          : { url: 'https://support.chronosphere.io', label: 'Contact Chronosphere Support' },
    };
  }
  if (isOfflineError(error)) {
    return {
      icon: OfflineIcon,
      severity: 'error',
      title: "It seems like you're offline",
      message: 'Check your network connection, and then refresh your browser.',
    };
  }
  if (isNotFoundError(error)) {
    return {
      headerText: '404',
      severity: 'error',
      title: error instanceof AppError ? error.title : "We can't find that resource",
      message:
        error instanceof AppError
          ? error.message
          : "The resource might not exist anymore, or there's a typo in the URL. Contact the owner for more information.",
    };
  }
  return {
    icon: ErrorIcon,
    severity: options ? options.severity : error instanceof AppError ? error.severity : 'error',
    title: options ? options.title : error instanceof AppError ? error.title : 'Something happened on our end',
    message: options
      ? options.message
      : error instanceof AppError
        ? error.message
        : 'Refresh your browser and contact Chronosphere support if the issue persists',
    serverMessages: getServerErrorMessages(error),
    link:
      options || error instanceof AppError
        ? undefined
        : { url: 'https://support.chronosphere.io', label: 'Contact Chronosphere Support' },
  };
}

export const borderColor = (severity: AppErrorSeverity, theme: Theme) => {
  if (severity === 'error') return theme.palette.error.main;
  else if (severity === 'warning') return theme.palette.warning.main;
  return theme.palette.border.prominent;
};

export const backgroundColor = (severity: AppErrorSeverity, theme: Theme) => {
  if (severity === 'error') return theme.palette.error.light;
  else if (severity === 'warning') return theme.palette.warning.light;
  return theme.palette.background.paper;
};

export const textColor = (severity: AppErrorSeverity, theme: Theme) => {
  if (severity === 'error') return theme.palette.error.dark;
  else if (severity === 'warning') return theme.palette.warning.dark;
  return theme.palette.text.secondary;
};
