import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { LogoPane } from '../../components';
import { fetch } from '../../utils';
import { ResetPasswordFormValues, ResetPasswordSchema } from './login-model';
import { ResetPasswordForm } from './ResetPasswordForm';

function ResetPassword() {
  const initialValues: ResetPasswordFormValues = {
    email: '',
  };

  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState<string>();

  const handleSubmit = async (values: ResetPasswordFormValues, helpers: FormikHelpers<ResetPasswordFormValues>) => {
    setServerError(undefined);
    try {
      await fetch('/auth/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setSuccess(true);
    } catch (err) {
      setServerError('There was a problem resetting your password. Please try again later.');
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <LogoPane>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ResetPasswordSchema}
        validateOnBlur
        validateOnChange={false}
      >
        <ResetPasswordForm success={success} serverError={serverError} />
      </Formik>
    </LogoPane>
  );
}

/**
 * BULK EDIT NOTE: Default exports are no longer recommended.
 * Use Named export on next refactor.
 * See http://go/cloud-ui-modules-brief for more info.
 */
// eslint-disable-next-line import/no-default-export
export default ResetPassword;
