import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { accessStorage } from '@chronosphereio/core';
import { CollectionType } from '@/model';
import { EntityLinkType } from '@/model/url/entity';

export const RECENT_TEAM_STORAGE_KEY = 'CHRONOSPHERE_RECENT_TEAM';
export const RECENT_RESOURCES_STORAGE_KEY = 'CHRONOSPHERE_RECENT_RESOURCES';
const MAX_RECENT_RESOURCES_SIZE = 10;

export enum ActiveResourceType {
  TEAM = 'team',
  COLLECTION = 'collection',
  SERVICE = 'service',
  SLO = 'slo',
  DASHBOARD = 'dashboard',
  MONITOR = 'monitor',
  TRACE_DATASET = 'traceDataset',
  TRACE_BEHAVIOR = 'traceBehavior',
  UNKNOWN = 'unknown',
}

export type ActiveResourceTypes = `${ActiveResourceType}`;

export type ActiveResourceCollectionLink = {
  name: string;
  slug: string;
  type: EntityLinkType;
};

export type ActiveResource =
  | {
      name?: string;
      slug?: string | null;
      type?: ActiveResourceType;
      resource?: unknown;
      collectionLinks?: ActiveResourceCollectionLink[] | null;
      parentCollection?:
        | (ActiveResource & {
            type: ActiveResourceType.COLLECTION | ActiveResourceType.SERVICE;
            collectionType?: CollectionType;
          })
        | null;
      parentTeam?:
        | (ActiveResource & {
            type: ActiveResourceType.TEAM;
          })
        | null;
    }
  | undefined;

const updateRecentResources = (
  recentArtifacts: ActiveResource[],
  newlyVisitedResource: ActiveResource
): ActiveResource[] => {
  let existingIndex = recentArtifacts.findIndex((resource) => newlyVisitedResource?.slug === resource?.slug);

  if (existingIndex === -1) {
    existingIndex = MAX_RECENT_RESOURCES_SIZE - 1;
  }

  const updatedRecentResources = [
    // Place the newly visited resource at the top of the list.
    newlyVisitedResource,
  ];

  // If the resource doesn't already exist in the list, then append all of the
  // pre-existing resources, capping the total at the max.
  if (existingIndex === -1) {
    return updatedRecentResources.concat(recentArtifacts.slice(0, MAX_RECENT_RESOURCES_SIZE));
  }

  // If the resource does exist in the list, append all of the pre-existing
  // resources up to but not including the existing resource.
  return updatedRecentResources.concat([
    ...recentArtifacts.slice(0, existingIndex),
    // Skip over it and append the rest, capping the total at the max.
    ...recentArtifacts.slice(existingIndex + 1, MAX_RECENT_RESOURCES_SIZE),
  ]);
};

export function useActiveResourceStore() {
  // Keep track of the active resource. Components querying resource details should use the setter to set the
  // currently active resource so navigational constructs can consume artifact details (e.g. name, owning resource, etc)
  const [activeResource, _setActiveResource] = useState<ActiveResource | null>(null);

  const setActiveResource = useCallback((activeResource?: ActiveResource | null) => {
    if (activeResource !== undefined) {
      _setActiveResource(activeResource);
      return;
    }
  }, []);

  const recentResources = useMemo(() => {
    const { getItem, setItem } = accessStorage<ActiveResource[]>(global.localStorage, RECENT_RESOURCES_STORAGE_KEY);

    // Hydrate from storage, default to empty array.
    const hydratedRecentResources = getItem() ?? [];

    // If there's no newly-visited team or collection, then we don't need
    // to update recent resources.
    if (
      !activeResource?.slug ||
      (activeResource.type !== ActiveResourceType.COLLECTION && activeResource.type !== ActiveResourceType.TEAM)
    ) {
      return hydratedRecentResources;
    }

    // Update recent artifacts with newly-visited team or collection.
    const updatedRecentResources = updateRecentResources(hydratedRecentResources, activeResource);

    // Persist to storage.
    setItem(updatedRecentResources);

    return updatedRecentResources;
  }, [activeResource]);

  return {
    activeResource,
    setActiveResource,
    recentResources,
  };
}

type ActiveResourceContextArgs = ReturnType<typeof useActiveResourceStore>;

export const ActiveResourceContext = React.createContext<null | ActiveResourceContextArgs>(null);

export const ActiveResourceProvider = ({ children }: { children: ReactNode }) => {
  return <ActiveResourceContext.Provider value={useActiveResourceStore()}>{children}</ActiveResourceContext.Provider>;
};

function useActiveResourceContext() {
  const ctx = useContext(ActiveResourceContext);
  if (ctx == null) {
    throw new Error('ActiveResourceContext not found. Did you forget a Provider?');
  }
  return ctx;
}

export const useActiveResource = () => {
  const activeResourceContext = useActiveResourceContext();

  return {
    activeResource: activeResourceContext.activeResource,
    setActiveResource: activeResourceContext.setActiveResource,
  };
};

/** Recently visited teams and collections */
export function useRecentResources() {
  const ActiveResourceContext = useActiveResourceContext();
  return ActiveResourceContext.recentResources;
}
