import React, { createContext, useMemo, useContext } from 'react';
import { ThemeProvider, usePrefersDarkModeMediaQuery } from '@chronosphereio/chrono-ui';
import { UITheme } from '../generated/graphql';
import { mapThemeSettingToMode, useLoadThemeSetting, useSaveThemeSetting } from './theme-context-model';

interface ThemeContextType {
  /**
   * The ThemeSetting for the current user. Will be undefined while being loaded.
   */
  themeSetting?: UITheme;
  /**
   * Save a new ThemeSetting for the current user to the server.
   */
  saveThemeSetting: (next: UITheme) => Promise<void>;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

type ThemeContextProviderProps = {
  applicationRoot: string;
  children: React.ReactNode;
};

export function ThemeContextProvider({ applicationRoot, children }: ThemeContextProviderProps) {
  const themeSetting = useLoadThemeSetting();

  const userPrefersDarkMode = usePrefersDarkModeMediaQuery();
  const saveThemeSetting = useSaveThemeSetting(userPrefersDarkMode);

  // Context for the current setting so the UI can read/write it
  const context: ThemeContextType = useMemo(
    () => ({ themeSetting, saveThemeSetting }),
    [themeSetting, saveThemeSetting]
  );

  // Figure out the mode to pass to Chrono UI
  const mode = themeSetting === undefined ? undefined : mapThemeSettingToMode(themeSetting, userPrefersDarkMode);

  return (
    <ThemeContext.Provider value={context}>
      <ThemeProvider applicationRoot={applicationRoot} mode={mode}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

/**
 * Gets the current ThemeContext and throws if undefined.
 */
export function useThemeContext(): ThemeContextType {
  const ctx = useContext(ThemeContext);
  if (ctx === undefined) {
    throw new Error('No ThemeContext found. Did you forget a Provider?');
  }
  return ctx;
}
