import {
  FetchOptions,
  UseFetchResponse,
  useFetch as useFetchShared,
  useLazyFetch as useLazyFetchShared,
  NotAuthenticatedError,
  FetchError,
  logError,
} from '@chronosphereio/core';
import { tryParseUrl } from './url';
import { trackRequestLatency } from './monitoring/monitoring';

export const AUTH_ERROR_HEADER = 'chrono-tenants-error';
const GRAPHQL_API_PATH = '/api/v1/gql/query';

/**
 * Determines if the request is a GQL query, if so return the query name, otherwise return undefined.
 */
export function getGQLQueryName(requestUrl: URL, init: RequestInit | undefined) {
  if (requestUrl.pathname !== GRAPHQL_API_PATH || init === undefined) {
    return undefined;
  }

  try {
    const body = JSON.parse(init?.body?.toString() ?? '');
    return body['operationName'];
  } catch {
    return undefined;
  }
}

/**
 * Same as window.fetch but throws on non-200 responses.
 */
export async function fetch(input: RequestInfo | URL, init?: RequestInit) {
  const start = new Date();
  let requestUrl = getRequestUrlFromFetchInput(input);

  let gqlQueryName: string | undefined = undefined;
  if (requestUrl !== undefined) {
    gqlQueryName = getGQLQueryName(requestUrl, init);

    // Also add the GQL query name to the URL for the request (if it's available)
    if (gqlQueryName !== undefined) {
      ({ input, requestUrl } = appendQueryNameToRequest(input, requestUrl, gqlQueryName));
    }
  }

  const res = await self.fetch(input, init);

  if (requestUrl !== undefined) {
    trackRequestLatency(start, {
      request_url: requestUrl,
      status: res.status,
      gql_query_name: gqlQueryName,
    });
  }

  if (res.ok) {
    return res;
  }

  if (res.status === 401) {
    // This is a special case for impersonation by Chronosphere employees. Handling is in LoginCallback, but we need
    // the full response and the related headers to do that handling
    if (res.headers?.get(AUTH_ERROR_HEADER)) {
      return res;
    }

    throw new NotAuthenticatedError(res);
  }

  throw new FetchError(res);
}

/**
 * Just like urql's useQuery, but for using fetch to retrieve data from a
 * non-GraphQL API.
 */
export function useFetch<T>(
  url: string,
  init?: RequestInit,
  options?: Omit<FetchOptions, 'fetchFn'>
): UseFetchResponse<T> {
  return useFetchShared<T>(url, init, { ...options, fetchFn: fetch });
}

/**
 * Like useFetch, only data is not fetched until the fetch function that's
 * returned is invoked (i.e. in an effect or callback).
 */
export function useLazyFetch<T>(url: string, init?: RequestInit): UseFetchResponse<T> {
  const options = { fetchFn: fetch };
  return useLazyFetchShared<T>(url, init, options);
}

/**
 * Helper function to convert the `input` param passed to fetch to a URL object for the URL being requested.
 */
function getRequestUrlFromFetchInput(input: RequestInfo | URL): URL | undefined {
  try {
    // input is a URL string (could be absolute or a relative path)
    if (typeof input === 'string') {
      // Try parsing as absolute first, then relative
      const url = tryParseUrl(input) ?? tryParseUrl(input, window.location.origin);
      if (url !== undefined) {
        return url;
      }

      throw new Error(`Invalid URL string passed to fetch: ${input}`);
    }

    // input is already a URL
    if ('searchParams' in input) {
      return input;
    }

    // input is a Request object
    return new URL(input.url);
  } catch (err) {
    logError(err);
    return undefined;
  }
}

/**
 * Helper function to add the `gqlQueryName` to both the `input` and `requestUrl` as a query string parameter.
 */
function appendQueryNameToRequest(input: RequestInfo | URL, requestUrl: URL, gqlQueryName: string) {
  // Create a copy and modify the request URL to add a query string param
  requestUrl = new URL(requestUrl);
  requestUrl.searchParams.append('gql_query_name', gqlQueryName);

  // Return the same type of input as the original
  if (typeof input === 'string') {
    input = requestUrl.toString();
  } else if ('searchParams' in input) {
    input = requestUrl;
  } else {
    // This is currently the recommended way to "clone" a request with a modified URL
    // (see https://github.com/whatwg/fetch/issues/245)
    input = new Request(requestUrl, input);
  }

  return { input, requestUrl };
}
