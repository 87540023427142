import { useState, useCallback } from 'react';

/**
 * Returns a callback for throwing async errors (i.e errors in async code
 * blocks, event handlers, etc.) so that they are propagated and able to be
 * handled by a React error boundary.
 */
export function useThrowAsyncError() {
  const [, setError] = useState<never>();

  // See: https://github.com/facebook/react/issues/14981#issuecomment-468460187
  const throwError = useCallback((error: unknown) => {
    setError(() => {
      throw error;
    });
  }, []);

  return throwError;
}
