import {
  BootFlags,
  getBootFlag,
  getTenantNamespace,
  logError,
  useAuth,
  useIsServiceAccountUser,
} from '@chronosphereio/core';

function userGuidingAllowed() {
  const userGuidingMode = getBootFlag(BootFlags.USERGUIDING_MODE);
  return userGuidingMode === 'enabled';
}

export function appendUserGuidingScript() {
  try {
    const tenant = getTenantNamespace();
    if (!userGuidingAllowed()) {
      return;
    }

    const SCRIPT_TAG_ID = `userguiding-${tenant}`;

    if (document.getElementById(SCRIPT_TAG_ID) !== null) {
      return;
    }

    const script = document.createElement('script');
    script.id = SCRIPT_TAG_ID;
    script.type = 'text/javascript';
    script.appendChild(document.createTextNode(SCRIPT_CONTENTS));
    document.body.appendChild(script);
  } catch (e) {
    console.error(e);
  }
}

interface UserGuidingApi {
  identify: (userId: string, attributes?: Record<string, string | number | boolean>) => void;
  launchSurvey: (surveyId: number) => void;
}

declare global {
  interface Window {
    userGuiding?: UserGuidingApi;
  }
}

const SCRIPT_CONTENTS = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','YNE76233VFWID');`;

export function useUserGuiding() {
  const { currentUser } = useAuth();
  const isServiceAccount = useIsServiceAccountUser();
  if (!userGuidingAllowed()) {
    return;
  }
  const userGuiding = window.userGuiding;
  if (userGuiding !== null && userGuiding !== undefined && Boolean(currentUser?.email) && isServiceAccount === false) {
    try {
      const tenant = getTenantNamespace();
      userGuiding.identify(currentUser?.email ?? '', {
        email: currentUser?.email ?? '',
        isSysAdmin: currentUser?.isAdmin ?? false,
        tenantName: tenant,
      });
    } catch (error) {
      logError(error);
    }
  }
}
