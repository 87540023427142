import React, { useMemo } from 'react';
import { ChartsProvider, generateChartsTheme, EChartsTheme, ChartsTheme } from '@chronosphereio/viz-framework';
import { useTheme } from '@chronosphereio/chrono-ui';

// TODO: remove overrides and generate theme approach
const ECHARTS_THEME_OVERRIDES: EChartsTheme = {};

export interface CloudChartsProviderProps {
  children: React.ReactNode;
}

export function CloudChartsProvider({ children }: CloudChartsProviderProps) {
  const muiTheme = useTheme();
  const chartsTheme: ChartsTheme = useMemo(() => {
    return generateChartsTheme(muiTheme.theme, ECHARTS_THEME_OVERRIDES, "[data-id='tooltipPortalId']");
  }, [muiTheme.theme]);

  return (
    <ChartsProvider chartsTheme={chartsTheme} enablePinning={true}>
      {children}
    </ChartsProvider>
  );
}
