import React from 'react';
import { Form } from 'formik';
import { TextField } from '@mui/material';
import { Box, Button, DEPRECATED_REACT_PROPS } from '@chronosphereio/chrono-ui';
import { SignupFormValues } from './signup-model';
import { useTextField, useSubmitButton } from '@/utils';

/**
 * Form for signing up for a new account.
 */
export function SignupForm() {
  const email = useTextField<SignupFormValues['email']>('email');
  const name = useTextField<SignupFormValues['name']>('name');
  const company = useTextField<SignupFormValues['company']>('company');
  const password = useTextField<SignupFormValues['password']>('password');
  const confirmPassword = useTextField<SignupFormValues['confirmPassword']>('confirmPassword');

  const submitButton = useSubmitButton();

  return (
    <Form {...DEPRECATED_REACT_PROPS}>
      <TextField {...email.textField} label="Email" fullWidth margin="normal" disabled />

      <TextField {...name.textField} label="Full Name" fullWidth margin="normal" />

      <TextField {...company.textField} label="Company" fullWidth margin="normal" disabled />

      <TextField {...password.textField} label="Password" fullWidth margin="normal" type="password" />

      <TextField {...confirmPassword.textField} label="Confirm Password" fullWidth margin="normal" type="password" />

      <Box mt={4}>
        <Button {...submitButton} fullWidth>
          Sign Up
        </Button>
      </Box>
    </Form>
  );
}
