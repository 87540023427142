import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Button, Alert } from '@chronosphereio/chrono-ui';
import SSOIcon from 'mdi-material-ui/CloudLock';
import { useAdminLoginMethods } from './use-login-methods';
import {
  CliLoginState,
  LOGIN_FROM_IDP_URL_PARAM,
  CLI_CALLBACK_URL_PARAM,
  CLI_IMPERSONATE_URL_PARAM,
} from './login-model';
import { CliLoginAlert } from './CliLoginAlert';
import { useThrowAsyncError } from '@/utils';
import { LogoPane, LoadingPane } from '@/components';

/**
 * The view for logging into the system.
 */
export function AdminLogin({ fromCli }: { fromCli?: boolean } = { fromCli: false }): JSX.Element {
  const { isLoading, loginWithSso } = useAdminLoginMethods(fromCli);

  const throwError = useThrowAsyncError();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const autoRedirect = loginWithSso !== undefined && params.has(LOGIN_FROM_IDP_URL_PARAM);
  const tokenCallback = params.get(CLI_CALLBACK_URL_PARAM) ?? undefined;

  // for CLI login flow, pass token callback param through Okta state
  const oktaState: CliLoginState = useMemo(() => {
    return fromCli ? { tokenCallback } : {};
  }, [fromCli, tokenCallback]);

  const handleSsoClick = (additionalScopes: string[]) => {
    // This shouldn't happen because we only allow for SSO to manage
    // impersonate endpoints.
    if (loginWithSso === undefined) {
      throw new Error('SSO login is not supported');
    }

    try {
      setIsRedirecting(true);
      loginWithSso([...additionalScopes], JSON.stringify(oktaState));
    } catch (err) {
      return throwError(err as Error);
    }
  };

  // CLI login: if impersonate=viewer, auto-login as viewer
  const cliImpersonate = params.get(CLI_IMPERSONATE_URL_PARAM);
  useEffect(() => {
    if (!loginWithSso) return; // wait until loginWithSso is available
    if (fromCli && cliImpersonate && cliImpersonate.match(/^viewer$/i) && isRedirecting === false) {
      try {
        setIsRedirecting(true);
        loginWithSso?.(['viewer'], JSON.stringify(oktaState));
      } catch (err) {
        return throwError(err as Error);
      }
    }
  }, [fromCli, loginWithSso, isRedirecting, cliImpersonate, oktaState, throwError]);

  const handleAdminSsoClick = () => {
    handleSsoClick(['admin']);
  };

  const handleViewerSsoClick = () => {
    handleSsoClick(['viewer']);
  };

  // Page can get in one of three states: loading, redirecting to SSO, or ready
  // for user input
  let content: React.ReactNode;
  if (isLoading) {
    content = <LoadingPane text="Loading Login" />;
  } else if (isRedirecting || autoRedirect) {
    content = <LoadingPane text="Redirecting to Single Sign On" />;
  } else if (fromCli && !tokenCallback) {
    content = (
      <>
        <CliLoginAlert />
        <Alert severity="warning">
          Callback URL was not provided. Provide a callback URL in order for your script to receive the authentication
          token.
        </Alert>
      </>
    );
  } else {
    content = (
      <>
        {fromCli && <CliLoginAlert />}
        {loginWithSso !== undefined && (
          <Stack spacing={2}>
            <Button StartIcon={<SSOIcon />} fullWidth onClick={handleViewerSsoClick}>
              Log in as Viewer
            </Button>
            <Button StartIcon={<SSOIcon />} variant="secondary" onClick={handleAdminSsoClick}>
              Log in as SysAdmin
            </Button>
          </Stack>
        )}
      </>
    );
  }

  return <LogoPane>{content}</LogoPane>;
}
