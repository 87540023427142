import React from 'react';
import { LocationDescriptorObject } from 'history';
import { sanitize } from 'dompurify';
/* eslint-disable no-restricted-imports */
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps, LinkTypeMap } from '@mui/material';
/* eslint-enable no-restricted-imports */
import LaunchIcon from 'mdi-material-ui/Launch';
import { Box, combineSx, Tab, TabProps } from '@chronosphereio/chrono-ui';
import { AppError, useFeatureFlags } from '@chronosphereio/core';
import { logError } from './errors/error-utils';

export type LinkProps = MuiLinkProps<RouterLink>;

const sanitizeUrl = (url: string) => {
  const allowedProtocols = ['http:', 'https:', 'mailto:'];
  try {
    const parsedUrl = new URL(url, window.location.origin);
    if (allowedProtocols.includes(parsedUrl.protocol)) {
      return sanitize(url);
    }
  } catch {
    return null;
  }

  return null;
};

/**
 * Wrapper around chrono-ui themed link that handles in-application routing. "to" prop is required.
 * Use if you are linking to any route inside of the Chronosphere SPA.
 * @param {string} to In-application route location
 * @returns {React.Component} Rendered MUI Link
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link({ children, to = '', ...props }, ref) {
  const location = useLocation();
  const { clientSideTracing } = useFeatureFlags();

  try {
    let linkTo: string | null | LocationDescriptorObject = '';

    if (typeof to === 'string') {
      linkTo = sanitizeUrl(to);
    }

    // If we cannot generate a safe URL, just return the text of the link with no href
    if (linkTo === null) {
      return <>{children}</>;
    }

    linkTo = typeof to === 'string' ? new URL(to, window.location.origin) : (to as LocationDescriptorObject);

    return (
      <MuiLink
        ref={ref}
        component={RouterLink}
        to={{
          pathname: linkTo.pathname,
          search: linkTo.search,
          state: { ...((linkTo as LocationDescriptorObject).state ?? {}), from: location.pathname },
        }}
        {...props}
      >
        {children}
      </MuiLink>
    );
  } catch (e) {
    logError(
      new AppError({ severity: 'warning', title: 'bad link generation', rootCause: e as Error }),
      clientSideTracing,
      undefined,
      'bad link generation'
    );

    // Log the error, but return a valid link anyway, falling back to the default 'to' behavior
    return (
      <MuiLink ref={ref} component={RouterLink} to={to} {...props}>
        {children}
      </MuiLink>
    );
  }
});

export type ExternalLinkProps = LinkTypeMap['props'] & {
  href: string;
};

/**
 * Wrapper around chrono-ui themed link with the addition of an "external link" icon. "href" is required.
 * Use if you are linking outside of the Chronosphere SPA.
 * @param {string} href String URL provided to the underlying anchor element
 * @returns {React.Component} Rendered MUI Link
 */
export const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
  { children, href, sx, ...props }: ExternalLinkProps,
  ref
) {
  const cleanedUrl = sanitizeUrl(href);

  // If we cannot generate a safe URL, just return the text of the link with no href
  if (cleanedUrl === null) {
    return (
      <Box
        sx={combineSx(
          {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
          },
          sx
        )}
      >
        {children}
      </Box>
    );
  }

  return (
    <MuiLink
      ref={ref}
      href={cleanedUrl}
      component="a"
      {...props}
      sx={combineSx(
        {
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        sx
      )}
      target="_blank"
      rel="noopener"
    >
      {children}
      <LaunchIcon fontSize="inherit" sx={{ marginLeft: (theme) => theme.spacing(0.5) }} />
    </MuiLink>
  );
});

const LinkAdapter = React.forwardRef<typeof Link, LinkProps>(({ href, ...theRest }, _ref) => {
  return href === undefined ? <Link {...theRest} /> : <Link {...theRest} to={href} />;
});
LinkAdapter.displayName = 'LinkAdapter';

/**
 * Tab that uses a <Link /> in order to navigate without a full page reload
 */
export function LinkTab(props: TabProps) {
  return <Tab LinkComponent={LinkAdapter} {...props} />;
}

/**
 * LinkButton to provide onClick functionality that has the styling of an anchor element. "onClick" prop
 * is required. Use if you do not have a meaningful href
 * @param {function} onClick Event handler called when the LinkButton is clicked
 * @returns {React.Component} Rendered MUI Link as a button
 */
export function LinkButton({
  children,
  onClick,
  ...props
}: Omit<LinkTypeMap['props'], 'href'> & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <MuiLink onClick={onClick} type="button" component="button" {...props}>
      {children}
    </MuiLink>
  );
}

/**
 * For rendering text that's styled like a Link, but doesn't actually Link to
 * anything. Can be useful if, for example, you have another component like
 * a ListItemButton that's _actually_ the clickable thing with an href, but you
 * want the button's text to look like a Link.
 */
export function LinkText({ children, ...others }: Pick<MuiLinkProps<'span'>, 'children' | 'sx' | 'className'>) {
  return (
    <MuiLink {...others} component="span">
      {children}
    </MuiLink>
  );
}
