import React from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from './ErrorBoundary';
import { PageFallback } from './PageFallback';

type PageErrorBoundaryProps = Omit<ErrorBoundaryProps, 'FallbackComponent'>;

/**
 * Error boundary with a Page fallback component
 */
export const PageErrorBoundary = (props: PageErrorBoundaryProps) => {
  return <ErrorBoundary FallbackComponent={PageFallback} {...props} />;
};
