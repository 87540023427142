import { Box, Typography } from '@chronosphereio/chrono-ui';
import { FallbackComponentProps, useResetOnLocationChange } from './error-utils';
import { ErrorFallbackContent } from './ErrorFallbackContent';

const HeaderText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      sx={(theme) => ({
        fontSize: 132,
        lineHeight: 1,
        textAlign: 'center',
        color: theme.palette.text.accent,
      })}
    >
      {children}
    </Typography>
  );
};

/**
 * Error boundary fallback component for page-level errors
 */
export function PageFallback(props: FallbackComponentProps) {
  const { headerText, title, message, link, icon: Icon, serverMessages } = props.errorDetails;
  useResetOnLocationChange(props.onReset);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={6}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
      // This is to help our automated tests look for errors on a page
      data-error-fallback
    >
      {Icon && <Icon sx={(theme) => ({ fontSize: 140, color: theme.palette.text.disabled })} />}
      {headerText && <HeaderText>{headerText}</HeaderText>}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={350}
        sx={(theme) => ({ color: theme.palette.text.secondary })}
      >
        <ErrorFallbackContent title={title} message={message} link={link} serverMessages={serverMessages} />
      </Box>
    </Box>
  );
}
