import {
  Redirect as ReactRouterRedirect,
  RedirectProps as ReactRouterRedirectProps,
  RouteProps,
  useRouteMatch,
} from 'react-router-dom';
import { trackRouteRedirection } from './monitoring/monitoring';

type RedirectProps = RouteProps & {
  /**
   * Takes a string redirect location that receives the matched route props and returns a redirect location
   */
  to: ReactRouterRedirectProps['to'];
};

/**
 *
 * @param {string|Array} path A route or a array of routes that will be matched
 * @param {string|Function} to A string URL location or a function that returns a string URL location based on the
 * matched route
 * @returns {React.Component} Rendered  react-router Redirect component
 */
export const Redirect = ({ path, to }: RedirectProps) => {
  const match = useRouteMatch(path as string | string[]);

  trackRouteRedirection({
    from: match?.path ?? '/',
    to: to.toString(),
  });

  return <ReactRouterRedirect to={to} />;
};
