import React from 'react';
import { SnackbarMessage } from 'notistack';
import { Alert } from '@chronosphereio/chrono-ui';
import { AlertSeverity } from './types';

export interface SnackbarContentProps {
  message: SnackbarMessage;
  severity: AlertSeverity;
}

export const SnackbarContent = React.forwardRef(function SnackbarContent(
  { message, severity, ...otherProps }: SnackbarContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Alert ref={ref} {...otherProps} severity={severity} sx={{ minWidth: 300 }} aria-describedby="snackbar-content">
      <div id="snackbar-content">{message}</div>
    </Alert>
  );
});
