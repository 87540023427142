/**
 * Checks whether the given string is a valid absolute URL.
 */
export function isUrl(maybeUrl: string) {
  // URL constructor parsing will fail if not a valid URL string
  try {
    const url = new URL(maybeUrl);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
}

/**
 * Tries to create a URL using the constructor params provided. Returns undefined if the constructor throw an Error.
 */
export function tryParseUrl(url: string | URL, base?: string | URL): URL | undefined {
  try {
    return new URL(url, base);
  } catch {
    return undefined;
  }
}
