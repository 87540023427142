import { useEffect } from 'react';
import {
  gql,
  useQuery,
  addContextToErrorLogging,
  useIsAuthenticated,
  chronosphereLogsClient,
  AnalyticsInternal,
} from '@chronosphereio/core';
import { LoadAnalytics, LoadAnalyticsContext } from '@/generated/graphql';
import { useConfigureFeedbackModalUser } from '@/utils/feedback';
import { useLoadThemeSetting } from '@/context/theme-context-model';

const LOAD_ANALYTICS = gql`
  query LoadAnalytics {
    me {
      id
      email
      isAdmin
    }
    tenant {
      id
      name
    }
    settings {
      buildVersion
    }
    userSettings {
      darkMode
    }
  }
`;

/**
 * Adds some additional details about the user to analytics, error reporting, etc. once the user has logged in.
 */
export function useAddUserContextToAnalytics(analytics: AnalyticsInternal) {
  const isAuthenticated = useIsAuthenticated();
  const themeSetting = useLoadThemeSetting();
  const [{ data: userDetails }] = useQuery<LoadAnalytics, never>({
    query: LOAD_ANALYTICS,
    context: LoadAnalyticsContext,
    pause: !isAuthenticated,
  });

  // Update analytics session details with user information
  useEffect(() => {
    if (userDetails == null) {
      return;
    }

    const userContext = {
      isSysAdmin: userDetails.me.isAdmin,
      email: userDetails.me.email,
      tenantId: userDetails.tenant.id,
      tenantName: userDetails.tenant.name,
      theme: themeSetting,
      systemThemeMode: window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'dark' : 'light',
    };

    // Confgure logging identity
    chronosphereLogsClient.identify(userDetails.me.id, userContext);
    // Confgure analytics identity
    analytics.setAdditionalUserContext({
      id: userDetails.me.id,
      ...userContext,
    });
  }, [analytics, userDetails, themeSetting]);

  // Also enrich error data sent to logs with some of this information
  useEffect(() => {
    if (userDetails == null) {
      return;
    }

    addContextToErrorLogging({
      user: {
        id: userDetails.me.id,
        // Check if user email has company domain to determine if user is internal logging into customer accounts
        internal: userDetails.me.email.includes('chronosphere.io'),
        isSysAdmin: userDetails.me.isAdmin,
      },
      graphql: {
        buildVersion: userDetails.settings.buildVersion,
      },
    });
  }, [userDetails]);

  // Add additional user info to the feedback modal
  const feedbackModal = useConfigureFeedbackModalUser();
  useEffect(() => {
    if (feedbackModal === undefined || userDetails == null) {
      return;
    }

    feedbackModal.setAdditionalUserContext({
      email: userDetails.me.email,
      isAdmin: userDetails.me.isAdmin,
    });
  }, [feedbackModal, userDetails]);
}
