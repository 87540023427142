import { useState } from 'react';
import constate from 'constate';
import { useIsInternalUser } from '@chronosphereio/core';

function useChronoAdminStoreInternal() {
  const [featureFlagModalOpen, setFeatureFlagModalOpen] = useState<boolean>(false);
  return {
    featureFlagModalOpen,
    setFeatureFlagModalOpen,
  };
}

export const [ChronoAdminProvider, useChronoAdminStore] = constate(useChronoAdminStoreInternal);

/**
 * A gate that only allows users with an email that includes '@chronosphere.io' to see the children
 */
export function ChronoAdminGate({ children }: { children: React.ReactNode }) {
  const isChronoAdmin = useIsInternalUser();
  if (!isChronoAdmin) {
    return null;
  }

  return <>{children}</>;
}
