import React from 'react';
import { Alert } from '@chronosphereio/chrono-ui';
import { LogoPane } from '../components';
import { AlertButton } from '@/chrono-ui-todos';
/**
 * View that the user is redirected to after successfully completing the Slack OAuth flow.
 */
export function SlackOAuthSuccess() {
  const slackLink = 'https://app.slack.com/client';
  const goToSlack = () => {
    window.location.href = slackLink;
    return null;
  };

  return (
    <LogoPane>
      <Alert severity="success" Action={<AlertButton onClick={goToSlack}>Open Slack</AlertButton>}>
        You have successfully connected Chronosphere to Slack. <p>You can safely close this window.</p>
      </Alert>
    </LogoPane>
  );
}
