import React from 'react';
/* eslint-disable no-restricted-imports */
import { Button, ButtonProps, IconButton, IconButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
/* eslint-enable no-restricted-imports */
import { combineSx } from '@chronosphereio/chrono-ui';

export type AlertButtonProps = Pick<ButtonProps, 'children' | 'variant' | 'sx' | 'onClick' | 'disabled' | 'endIcon'>;

/**
 * A Button used inside an MUI Alert, usually as the `action` prop. Our current
 * button options in Chrono UI don't look great when used this way. You can see
 * this use case in the MUI docs here:
 *
 * https://mui.com/material-ui/react-alert/#actions
 */
export function AlertButton({ variant = 'text', sx, children, ...props }: AlertButtonProps) {
  return (
    <Button
      {...props}
      variant={variant}
      sx={combineSx(
        (theme) => ({
          // This basically restores MUIs styles that we're overriding
          color: 'inherit',
          borderColor: 'inherit',
          '&:hover': {
            color: 'inherit',
            borderColor: 'inherit',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(42, 46, 66, 0.04)',
          },
        }),
        sx
      )}
    >
      {children}
    </Button>
  );
}

export type AlertIconButtonProps = Pick<IconButtonProps, 'children' | 'sx' | 'onClick' | 'disabled'>;

/**
 * A Button used inside an MUI Alert, usually as the `action` prop. Our current
 * button options in Chrono UI don't look great when used this way. You can see
 * this use case in the MUI docs here:
 *
 * https://mui.com/material-ui/react-alert/#actions
 */
export function AlertIconButton({ sx, children, ...props }: AlertIconButtonProps) {
  return (
    <IconButton
      {...props}
      sx={combineSx(
        (theme) => ({
          // This basically restores MUIs styles that we're overriding
          color: 'inherit',
          borderColor: 'inherit',
          '&:hover': {
            color: 'inherit',
            borderColor: 'inherit',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(42, 46, 66, 0.04)',
          },
        }),
        sx
      )}
    >
      {children}
    </IconButton>
  );
}

export type ButtonGroupButtonProps = Pick<ButtonProps, 'children' | 'onClick' | 'disabled' | 'className'>;

/**
 * A Button when used in a ButtonGroup. This is currently only used in one part
 * of the product and so we haven't tackled the ButtonGroup use case yet. See
 * the MUI Docs here:
 *
 * https://mui.com/material-ui/react-button-group/
 */
export function ButtonGroupButton({ children, ...props }: ButtonGroupButtonProps) {
  return (
    <Button
      {...props}
      sx={
        // This only helps the one use-case we have at the moment look OK (where
        // it's using an outlined variant ButtonGroup). If we end up with more,
        // we may need to tackle this ASAP
        (theme) => ({ color: theme.palette.text.primary })
      }
    >
      {children}
    </Button>
  );
}

export type LinkButtonProps = Pick<
  ButtonProps<Link<unknown>>,
  'to' | 'variant' | 'startIcon' | 'target' | 'rel' | 'sx' | 'children' | 'onClick'
>;

/**
 * A Button that renders using an `a` tag with an href but navigates using
 * `react-router-dom`. There aren't any visual issues with this and the current
 * Button styles in Chrono UI, but this takes advantage of MUI's `component`
 * prop, which we're trying not to expose in the Chrono UI Button. I suspect
 * that two of the current use cases (buttons that open editor modals), should
 * not actually be links/routes and just need to be refactored.
 *
 * The other wrinkle here that's probably preventing us from just putting this
 * into Chrono UI is that we probably don't want our components there to know
 * about `react-router-dom`.
 *
 * Not to be confused with the OTHER LinkButton component, which should maybe
 * be named ButtonLink instead? Maybe link typography styles should be a mixin
 * for buttons that need to look like links?
 */
export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(function LinkButton(
  { children, ...props },
  ref
) {
  return (
    <Button {...props} ref={ref} component={Link}>
      {children}
    </Button>
  );
});

export type LinkButtonHrefProps = Pick<
  ButtonProps<'a'>,
  'href' | 'startIcon' | 'endIcon' | 'size' | 'sx' | 'children' | 'onClick' | 'target' | 'rel' | 'variant'
>;

/**
 * This is just like the LinkButton use case above, only it uses an href to do
 * navigation (bypassing React Router). Again, no styling issues with how this
 * looks under Chrono UI, but it uses the `component` prop that we're trying to
 * avoid exposing.
 */
export const LinkButtonHref = React.forwardRef<HTMLAnchorElement, LinkButtonHrefProps>(function LinkButtonHref(
  { children, ...props },
  ref
) {
  return (
    <Button {...props} ref={ref} component="a">
      {children}
    </Button>
  );
});

export type UploadButtonProps = Pick<
  ButtonProps<'label'>,
  'variant' | 'startIcon' | 'sx' | 'id' | 'htmlFor' | 'children'
>;

/**
 * A Button that renders as a `label` element, meant to be used to trigger a
 * file input. This is another edge case where there aren't any visual issues
 * with the current Button styles in Chrono UI, but it takes advantage of the
 * MUI `component` prop. It's sort of strange in that this button doesn't have
 * an `onClick` handler, but instead takes advantage of the browser's behavior
 * that clicking on the `label` for a file input will open the file selection
 * dialog. (I think it's impossible to otherwise trigger that dialog from a
 * click handler in JavaScript which is why this workaround exists, but might
 * be worth investigating?)
 *
 * The MUI example that this is based on is here:
 * https://mui.com/material-ui/react-button/#upload-button
 */
export function UploadButton({ children, ...props }: UploadButtonProps) {
  return (
    <Button {...props} component="label">
      {children}
    </Button>
  );
}
