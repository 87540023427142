import { ReactNode, useState } from 'react';
import { Collapse } from '@mui/material';
import { Box } from '@chronosphereio/chrono-ui';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import ChevronUp from 'mdi-material-ui/ChevronUp';
import TitleIcon from 'mdi-material-ui/AlertOutline';
import { AppErrorSeverity } from '@chronosphereio/core';
import { ExternalLink } from '../Link';
import { AlertButton } from '@/chrono-ui-todos';
import { textColor } from '@/model';

interface ErrorFallbackContentProps {
  title: string;
  severity?: AppErrorSeverity;
  message?: string;
  serverMessages?: string[];
  link?: { url: string; label: string };
  icon?: ReactNode;
}

export function ErrorFallbackContent({
  title,
  severity,
  message,
  serverMessages,
  link,
  icon,
}: ErrorFallbackContentProps) {
  const [detailsVisible, setDetailsVisible] = useState(false);
  return (
    <>
      {title && (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          fontSize="1rem"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
        >
          {icon ?? <TitleIcon />} {title}
        </Box>
      )}
      {message}
      {serverMessages && (
        <Box display="flex" flexDirection="column">
          <div>
            <AlertButton
              onClick={() => setDetailsVisible((state) => !state)}
              endIcon={detailsVisible ? <ChevronUp /> : <ChevronDown />}
            >
              Error Details
            </AlertButton>
          </div>
          <Collapse in={detailsVisible}>
            <div>
              {serverMessages.map((msg, idx) => (
                <Box key={idx} sx={{ paddingX: 2 }}>
                  {msg}
                </Box>
              ))}
            </div>
          </Collapse>
        </Box>
      )}
      {link && (
        <ExternalLink
          href={link.url}
          sx={severity && { color: (theme) => textColor(severity, theme), textDecoration: 'underline' }}
        >
          {link.label}
        </ExternalLink>
      )}
    </>
  );
}
