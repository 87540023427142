import { Suspense, lazy, useContext, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { AuthenticationContext, getLoginPageLocation, chronosphereLogsClient } from '@chronosphereio/core';
import { useUnpinTimeRange } from '@chronosphereio/viz-framework';
import { useIsDarkMode } from '@chronosphereio/chrono-ui';
import { AuthRoutes, BaseRoutes, RouteConstants, PublicRoutes } from '@/model/Routes';
import { LoadingPane } from '@/components';
import Login from '@/views/login/Login';
import { LoginCallback } from '@/views/LoginCallback';
import { CliLoginCallback } from '@/views/CliLoginCallback';
import { LoginPkceCallback } from '@/views/LoginPkceCallback';
import ResetPassword from '@/views/login/ResetPassword';
import { AdminLogin } from '@/views/login/AdminLogin';
import { Logout } from '@/views/Logout';
import LogoutCallback from '@/views/LogoutCallback';
import SignupInvite from '@/views/signup/Invite';
import { Redirect, trackPageVisit } from '@/utils';
import { SlackOAuthCallback } from '@/views/SlackOAuthCallback';
import { SlackOAuthSuccess } from '@/views/SlackOAuthSuccess';

const prependRoutePath = (prefix: string, route: string) => `${prefix}${route}`;
const withV3 = (route: string) => prependRoutePath(RouteConstants.V3, route);

const ProtectedApp = lazy(async () => {
  const { ProtectedApp } = await import('@/ProtectedApp');
  return { default: ProtectedApp };
});

const PublicDashboard = lazy(async () => {
  const { PublicDashboardPage } = await import('@/components/dashboards/PublicDashboard');
  return { default: PublicDashboardPage };
});

const APPLICATION_ID = 'root';

export function App() {
  const { isLoading, currentUser } = useContext(AuthenticationContext);
  const isDarkMode = useIsDarkMode();
  const history = useHistory();

  // Unpin the time range when a tab is closed
  useUnpinTimeRange();

  useEffect(() => {
    const rootElement = document.getElementById(APPLICATION_ID);

    if (rootElement) {
      rootElement.style.colorScheme = isDarkMode ? 'dark' : 'light';
    }
  }, [isDarkMode]);

  useEffect(() => {
    let prevPathname = history.location.pathname;

    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== prevPathname) {
        chronosphereLogsClient.page();
        trackPageVisit(prevPathname, pathname);
        prevPathname = pathname;
      }
    });

    return unlisten;
  }, [history]);

  // Figure out what to render if we're not on a route that's public
  let catchAll: JSX.Element;
  if (isLoading) {
    // Show a loading screen until we know whether they are logged in
    catchAll = <LoadingPane text="Loading Chronosphere" />;
  } else if (currentUser === undefined) {
    // We know they aren't logged in, so redirect them to the login page
    const redirectLocation = getLoginPageLocation(BaseRoutes.LOGIN);
    catchAll = <Redirect to={redirectLocation} />;
  } else {
    // We know they are logged in, so let them into the rest of the app
    catchAll = <ProtectedApp />;
  }

  return (
    <Suspense fallback={<LoadingPane text="Loading Chronosphere" />}>
      <Switch>
        <Route path={PublicRoutes.PUBLIC_DASHBOARD}>
          <PublicDashboard />
        </Route>
        <Route path={[BaseRoutes.LOGIN, withV3(BaseRoutes.LOGIN)]} exact>
          <Login />
        </Route>
        <Route path={[AuthRoutes.LOGIN_CALLBACK, withV3(AuthRoutes.LOGIN_CALLBACK)]}>
          <LoginCallback />
        </Route>
        <Route path={[AuthRoutes.LOGIN_PKCE_CALLBACK, withV3(AuthRoutes.LOGIN_PKCE_CALLBACK)]}>
          <LoginPkceCallback />
        </Route>
        <Route path={[AuthRoutes.IMPERSONATE, withV3(AuthRoutes.IMPERSONATE)]} exact>
          <AdminLogin />
        </Route>
        <Route path={[AuthRoutes.IMPERSONATE_CALLBACK, withV3(AuthRoutes.IMPERSONATE_CALLBACK)]}>
          <LoginCallback useImpersonation />
        </Route>
        <Route path={[AuthRoutes.IMPERSONATE_PKCE_CALLBACK, withV3(AuthRoutes.IMPERSONATE_PKCE_CALLBACK)]}>
          <LoginPkceCallback useImpersonation />
        </Route>
        <Route path={[AuthRoutes.RESET_PASSWORD, withV3(AuthRoutes.RESET_PASSWORD)]}>
          <ResetPassword />
        </Route>
        <Route path={[BaseRoutes.LOGOUT, withV3(BaseRoutes.LOGOUT)]} exact>
          <Logout />
        </Route>
        <Route path={[AuthRoutes.LOGOUT_CALLBACK, withV3(AuthRoutes.LOGOUT_CALLBACK)]}>
          <LogoutCallback />
        </Route>
        <Route path={[BaseRoutes.SIGNUP_INVITE, withV3(AuthRoutes.LOGOUT_CALLBACK)]}>
          <SignupInvite />
        </Route>
        <Route path={[AuthRoutes.SLACK_OAUTH_CALLBACK, withV3(AuthRoutes.SLACK_OAUTH_CALLBACK)]}>
          <SlackOAuthCallback />
        </Route>
        <Route path={[AuthRoutes.SLACK_OAUTH_SUCCESS, withV3(AuthRoutes.SLACK_OAUTH_SUCCESS)]}>
          <SlackOAuthSuccess />
        </Route>
        <Route path={[AuthRoutes.CLI_LOGIN, withV3(AuthRoutes.CLI_LOGIN)]} exact>
          <Login fromCli />
        </Route>
        <Route path={[AuthRoutes.CLI_LOGIN_CALLBACK, withV3(AuthRoutes.CLI_LOGIN_CALLBACK)]}>
          <CliLoginCallback />
        </Route>
        <Route path={[AuthRoutes.CLI_IMPERSONATE, withV3(AuthRoutes.CLI_IMPERSONATE)]} exact>
          <AdminLogin fromCli />
        </Route>
        <Route path={[AuthRoutes.CLI_IMPERSONATE_CALLBACK, withV3(AuthRoutes.CLI_IMPERSONATE_CALLBACK)]}>
          <CliLoginCallback useImpersonation />
        </Route>
        <Route>{catchAll}</Route>
      </Switch>
    </Suspense>
  );
}
