import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingPane } from '../components';
import { useLazyFetch } from '@/utils';
import { AuthRoutes } from '@/model/Routes';

type OAuthState = {
  subdomain?: string;
  nonce?: string;
};

/**
 * View that the user is redirected before we complete the Slack OAuth flow.
 */
export function SlackOAuthCallback() {
  const history = useHistory();

  const exchangeParams = new URLSearchParams(history.location.search);
  const code = exchangeParams.get('code');
  if (code === null) {
    throw new Error('invalid oauth callback');
  }

  const givenState = exchangeParams.get('state');
  if (givenState === null) {
    throw new Error('invalid oauth callback');
  }
  const state: OAuthState = JSON.parse(decodeURIComponent(givenState));
  if (state.subdomain === undefined) {
    throw new Error('invalid oauth callback');
  }

  exchangeParams.set('code', code);
  exchangeParams.set('subdomain', state.subdomain);
  const prefix = '/auth/slack/exchange';
  const redirectRoute = AuthRoutes.SLACK_OAUTH_SUCCESS;
  const [{ response: exchangeResponse, error: exchangeError }, exchange] = useLazyFetch(
    `${prefix}?${exchangeParams.toString()}`
  );
  if (exchangeError !== undefined) {
    throw exchangeError;
  }

  useEffect(() => {
    if (code !== null) {
      exchange();
    }
  }, [code, exchange]);

  useEffect(() => {
    if (exchangeResponse !== undefined) {
      history.replace(redirectRoute);
    }
  }, [exchange, exchangeResponse, history, redirectRoute]);

  return <LoadingPane text="Logging In" />;
}
