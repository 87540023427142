import { Form } from 'formik';
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Collapse,
  styled,
} from '@mui/material';
import { Box, Alert, Button, DEPRECATED_REACT_PROPS } from '@chronosphereio/chrono-ui';
import EmailIcon from 'mdi-material-ui/Account';
import PasswordIcon from 'mdi-material-ui/Lock';
import { Link } from '@/components';
import { useInput, useSubmitButton } from '@/utils';
import { AuthRoutes } from '@/model/Routes';

const InputIcon = styled('div')(({ theme }) => ({
  color: theme.palette.text.accent,
}));

export interface LoginFormProps {
  invalidCredentials: boolean;
}

/**
 * The form for logging in.
 */
export function LoginForm(props: LoginFormProps): JSX.Element {
  const { invalidCredentials } = props;
  const email = useInput('email');
  const password = useInput('password');

  const SubmitButtonProps = useSubmitButton();

  return (
    <Form {...DEPRECATED_REACT_PROPS}>
      <Collapse in={invalidCredentials} unmountOnExit>
        <Alert
          severity="error"
          sx={{
            marginBottom: (theme) => theme.spacing(2),
          }}
        >
          Authentication failed. Please ensure your email and password are correct.
        </Alert>
      </Collapse>

      <FormControl {...email.formControl} fullWidth>
        <InputLabel {...email.inputLabel}>Email</InputLabel>

        <OutlinedInput
          {...email.input}
          label="Email"
          startAdornment={
            <InputAdornment position="start">
              <InputIcon as={EmailIcon} />
            </InputAdornment>
          }
          size="medium"
        />

        <FormHelperText {...email.formHelperText} />
      </FormControl>

      <FormControl {...password.formControl} fullWidth margin="normal">
        <InputLabel {...password.inputLabel}>Password</InputLabel>

        <OutlinedInput
          {...password.input}
          type="password"
          label="Password"
          startAdornment={
            <InputAdornment position="start">
              <InputIcon as={PasswordIcon} />
            </InputAdornment>
          }
          size="medium"
        />

        <FormHelperText {...password.formHelperText} />
      </FormControl>

      <Box
        sx={{
          textAlign: 'right',
          marginBottom: (theme) => theme.spacing(4),
        }}
      >
        <Link to={AuthRoutes.RESET_PASSWORD} sx={{ textTransform: 'uppercase' }}>
          Forgot Password?
        </Link>
      </Box>

      <Button {...SubmitButtonProps} fullWidth>
        Log In
      </Button>
    </Form>
  );
}
