import { useCallback, useEffect } from 'react';
import { ThemeMode } from '@chronosphereio/chrono-ui';
import { gql, useQuery, useMutation, OperationContext, useIsAuthenticated } from '@chronosphereio/core';
import { fetch } from '../utils/fetch';
import {
  SchemaTypenames,
  UITheme,
  DarkModeSettingQuery,
  DarkModeSettingQueryContext,
  SaveThemePreferenceMutation,
  SaveThemePreferenceMutationVariables,
} from '../generated/graphql';

const LOAD_DARK_MODE = gql`
  query DarkModeSettingQuery {
    userSettings {
      uiTheme
    }
  }
`;

export function useSyncGrafanaTheme() {
  const canMatchMedia = window && window.matchMedia !== undefined;
  const themeSetting = useLoadThemeSetting();
  const updateThemeIfMismatch = useCallback(async () => {
    if (!canMatchMedia) return;
    if (themeSetting === UITheme.SYSTEM) {
      const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const currentColorScheme = userPrefersDarkMode ? 'dark' : 'light';
      const res = await getGrafanaPreference();
      const json = await res.json();
      if (json.theme !== currentColorScheme) {
        const updateRes = await updateGrafanaPreference(currentColorScheme);
        const updateJson = await updateRes.json();
        if (updateJson.message === 'Preferences updated') {
          window.location.reload();
        }
      }
    }
  }, [canMatchMedia, themeSetting]);

  // If the grafana theme desynced from the system, updated it
  useEffect(() => {
    updateThemeIfMismatch();
  }, [updateThemeIfMismatch]);

  // Update the grafana theme if the user changes their OS setting
  useEffect(() => {
    if (!canMatchMedia) return;
    const mediaMatch = window.matchMedia('(prefers-color-scheme: dark)');
    mediaMatch.addEventListener('change', updateThemeIfMismatch);
    return () => {
      mediaMatch.removeEventListener('change', updateThemeIfMismatch);
    };
  }, [canMatchMedia, updateThemeIfMismatch]);
}

/**
 * Loads the current user's ThemeSetting from the server and returns it once loaded.
 */
export function useLoadThemeSetting(): UITheme | undefined {
  const isAuthenticated = useIsAuthenticated();

  const [{ data }] = useQuery<DarkModeSettingQuery, never>({
    query: LOAD_DARK_MODE,
    context: DarkModeSettingQueryContext,
    pause: !isAuthenticated,
  });

  if (data === undefined) return undefined;

  // Default to SYSTEM when setting has not been set by the user yet
  const { uiTheme } = data.userSettings;
  if (uiTheme === UITheme.UNKNOWN) return UITheme.SYSTEM;
  return uiTheme;
}

const SAVE_THEME_PREFERENCE = gql`
  mutation SaveThemePreferenceMutation($input: UIThemeInput!) {
    setUITheme(input: $input)
  }
`;

// setUITheme return a boolean and we need to invalidate UserSettings
const SAVE_THEME_PREFERENCE_CONTEXT: Partial<OperationContext> = {
  additionalTypenames: [SchemaTypenames.UserSettings],
};

/**
 * Returns a callback that saves the current user's setting to the server in both
 * the Cloud UI and Grafana.
 */
export function useSaveThemeSetting(userPrefersDarkMode: boolean) {
  const [, saveThemePreference] = useMutation<SaveThemePreferenceMutation, SaveThemePreferenceMutationVariables>(
    SAVE_THEME_PREFERENCE
  );

  return useCallback(
    async (themeSetting: UITheme): Promise<void> => {
      await Promise.all([
        saveThemePreference(
          {
            input: { uiTheme: themeSetting },
          },
          SAVE_THEME_PREFERENCE_CONTEXT
        ),
        // TODO: stop calling grafana once backend GQL supports syncing this
        updateGrafanaPreference(mapThemeSettingToMode(themeSetting, userPrefersDarkMode)),
      ]);
    },
    [saveThemePreference, userPrefersDarkMode]
  );
}

/**
 * Calls the Grafana API to update the theme for the current user in Grafana so
 * that changes will be reflected in the Grafana UI in the iframe.
 */
function updateGrafanaPreference(preference: ThemeMode) {
  return fetch('/grafana/api/user/preferences', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      theme: preference,
    }),
  });
}

/**
 * Calls the Grafana API to get the theme for the current user in Grafana
 */
function getGrafanaPreference() {
  return fetch('/grafana/api/user/preferences', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Maps a ThemeSetting to the appropriate Mode for use by the Chrono UI ThemeProvider.
 */
export function mapThemeSettingToMode(themeSetting: UITheme, userPrefersDarkMode: boolean): ThemeMode {
  switch (themeSetting) {
    // If the user has explicitly specified system OR we don't know yet, use the
    // preference provided via media query
    case UITheme.SYSTEM:
    case UITheme.UNKNOWN:
      return userPrefersDarkMode ? 'dark' : 'light';
    case UITheme.DARK:
      return 'dark';
    case UITheme.LIGHT:
      return 'light';
  }
}
