const DEFAULT_RANDOM_CHARS = 'abcdefghijklmkopqrstuvwxyz0123456789';

/**
 * Fills an array with random values
 */
export type RNG = (a: Uint8Array) => void;

const defaultRNG: RNG = (a: Uint8Array) => {
  if (window?.crypto !== null && window?.crypto !== undefined) {
    window.crypto.getRandomValues(a);
  } else {
    // Non-crypto RNG
    for (let i = 0; i < a.length; i++) {
      a[i] = Math.random() % 255;
    }
  }
};

/**
 * A deterministic random number generator, suitable for use in tests
 *
 * @param a The array to populate with random values
 */
export const fakeRNG: RNG = (a: Uint8Array) => {
  for (let i = 0; i < a.length; i++) {
    a[i] = 255 - (i % 255);
  }
};

let rng: RNG = defaultRNG;

/**
 * Sets the random number generator to the default RNG
 */
export function usingDefaultRNG() {
  rng = defaultRNG;
}

/**
 * Sets the random number generator that should be used. Allows tests to override the default random
 * number generator.
 *
 * @param override The RNG to use.
 */
export function usingRNG(override: RNG) {
  rng = override;
}

/**
 * Generate a random string of the given len from the provided set of characters. Uses
 * the crypto PRG so is suitable for auth-related values such as nonces.
 */
export function randomString(len = 32, charset: string = DEFAULT_RANDOM_CHARS): string {
  const randomBytes = new Uint8Array(len);
  rng(randomBytes);
  const randomChars = randomBytes.map((n) => charset.charCodeAt(Math.abs(n) % charset.length));
  return String.fromCharCode(...Array.from(randomChars));
}
