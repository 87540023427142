// eslint-disable-next-line no-restricted-imports
import * as FullStory from '@fullstory/browser';
import { FULL_STORY_ORG_ID } from '@chronosphereio/shared';
import { logError, AnalyticsInternal, AnalyticsUserData, EventPayload } from '@chronosphereio/core';

const FS_RELAY_HOST = 'fsrelay.chronosphere.io';

/**
 * Analytics client that reports events to FullStory.
 */
export class FullStoryAnalytics implements AnalyticsInternal {
  static isInitialized = false;
  static useRelay = false;

  constructor(useRelay = false) {
    FullStoryAnalytics.useRelay = useRelay;
  }

  track(eventName: string, payload: EventPayload = {}) {
    try {
      // For more information on FullStory events, see:
      // https://developer.fullstory.com/custom-events
      FullStory.event(eventName, payload);
    } catch (err) {
      logError(err);
    }
  }

  trackMetric(eventName: string, labels: EventPayload = {}) {
    try {
      // For more information on FullStory events, see:
      // https://developer.fullstory.com/custom-events
      FullStory.event(eventName, labels);
    } catch (err) {
      logError(err);
    }
  }

  setAdditionalUserContext(context: AnalyticsUserData): void {
    try {
      FullStory.setUserVars(context);
    } catch (err) {
      logError(err);
    }
  }

  initialize(): void {
    // Class is meant to be used like a singleton, but guard against multiple initializations
    if (FullStoryAnalytics.isInitialized) {
      console.error('Received multiple calls to initialize FullStoryAnalytics');
      return;
    }

    // No try...catch here to allow consumer to know about and log errors if we can't initialize
    FullStory.init({
      orgId: FULL_STORY_ORG_ID,
      devMode: false,
      debug: false,
      host: FullStoryAnalytics.useRelay === true ? FS_RELAY_HOST : undefined,
      script: FullStoryAnalytics.useRelay === true ? `${FS_RELAY_HOST}/s/fs.js` : undefined,
    });
    FullStoryAnalytics.isInitialized = true;
  }
}
