import { AnalyticsInternal } from '@chronosphereio/core';

/**
 * Analytics client implementation for sending analytics events off into the void.
 */
export class NoOpAnalytics implements AnalyticsInternal {
  track(): void {
    // No-op
  }
  trackMetric(): void {
    // No-op
  }

  setAdditionalUserContext(): void {
    // No-op
  }
}
