import { formatInTimeZone } from 'date-fns-tz';
// timezone-standardization
// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns';

export const TIME_FORMAT = 'hh:mm:ss a';
export const DATE_TIME_FORMAT = `MM/dd/yyyy ${TIME_FORMAT}`;
const VERBOSE_DATE_TIME_FORMAT = `MMM d, yyyy, h:mm:ss a`;
const DATE_FORMAT = `M/d/yyyy`;
export const SHORT_TIME_FORMAT = 'h:mm:ss a';

export function formatRate(x: number) {
  if (x < 1000) {
    return `${x.toFixed(2)}/s`;
  } else if (x < 0.05) {
    return `< 0.05/s`;
  } else {
    return `${(x / 1000.0).toFixed(1)}k/s`;
  }
}

export function formatPercent(x: number): string {
  return `${x.toFixed(2)}%`;
}

export function formatDuration(x: number): string {
  if (x < 1.0) {
    return `${parseInt(String(x * 1000), 10)}ms`;
  } else if (x < 60.0) {
    return `${x.toFixed(1)}s`;
  } else if (x < 3600.0) {
    const minutes = Math.floor(x / 60);
    const seconds = Math.floor(x % 60);
    return `${minutes}m ${seconds}s`;
  } else {
    const hours = Math.floor(x / 3600);
    const minutes = Math.floor((x % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }
}

export function formatMicroseconds(microseconds: number) {
  if (microseconds > 1_000_000) {
    return `${(microseconds / 1_000_000).toFixed(2)}s`;
  }

  if (microseconds > 1_000) {
    return `${(microseconds / 1_000).toFixed(2)}ms`;
  }

  return `${microseconds}μs`;
}

export function dateFormatOptionsWithTimeZone(dateFormatOptions: Intl.DateTimeFormatOptions, timeZone?: string) {
  /*
   * if timeZone is provided, and is not local|browser,
   * then set timeZone option (recognize UTC regardless of uppercase/lowercase)
   * otherwise, default to browser timeZone setting
   */
  if (timeZone) {
    const lowerTimeZone = timeZone.toLowerCase();
    if (lowerTimeZone !== 'local' && lowerTimeZone !== 'browser') {
      return {
        ...dateFormatOptions,
        timeZone: lowerTimeZone === 'utc' ? 'UTC' : timeZone,
      };
    }
  }
  return dateFormatOptions;
}

export function formatWithTimeZone(date: Date, formatString: string, timeZone?: string) {
  /*
   * if timeZone is provided, and is not local|browser,
   * then format using timeZone option (recognize UTC regardless of uppercase/lowercase)
   * otherwise, format without timeZone option, defaulting to browser timeZone setting
   */
  const lowerTimeZone = timeZone?.toLowerCase();
  if (!timeZone || lowerTimeZone === 'local' || lowerTimeZone === 'browser') {
    return format(date, formatString);
  } else {
    return formatInTimeZone(date, lowerTimeZone === 'utc' ? 'UTC' : timeZone, formatString);
  }
}

export function getFormattedDateString(timeString: string | number, timeZone?: string) {
  try {
    return formatWithTimeZone(new Date(timeString), DATE_FORMAT, timeZone);
  } catch {
    return '';
  }
}

export function getFormattedTimeString(timeString: string | number, timeZone?: string) {
  try {
    return formatWithTimeZone(new Date(timeString), SHORT_TIME_FORMAT, timeZone);
  } catch {
    return '';
  }
}

export function getUserTimeZoneAbbr(timeZone?: string) {
  const lang = 'en-us';

  const options: Intl.DateTimeFormatOptions = { timeZoneName: 'short' };
  if (timeZone && timeZone !== 'local') {
    options.timeZone = timeZone === 'utc' ? 'UTC' : timeZone;
  }

  return new Intl.DateTimeFormat(lang, options)?.formatToParts(new Date())?.find((part) => part.type === 'timeZoneName')
    ?.value;
}

export function getVerboseFormattedDateString(timeString: string | number, timeZone?: string) {
  try {
    const abbr = getUserTimeZoneAbbr(timeZone);
    return `${formatWithTimeZone(new Date(timeString), VERBOSE_DATE_TIME_FORMAT, timeZone)} ${abbr}`;
  } catch {
    return '';
  }
}

function spansMultipleDays(minTime: string, maxTime: string) {
  const formatter = (time: string) => format(new Date(time), 'MM/dd/yyyy');

  return formatter(minTime) !== formatter(maxTime);
}

export function getBetweenDateSpan(minTime: string, maxTime: string, timeZone?: string) {
  const formattedMinDate = getFormattedDateString(minTime, timeZone);
  const formattedMaxDate = getFormattedDateString(maxTime, timeZone);

  if (formattedMinDate === '' || formattedMaxDate === '') {
    return '';
  }

  return spansMultipleDays(minTime, maxTime) ? `${formattedMinDate} - ${formattedMaxDate}` : `${formattedMinDate}`;
}
