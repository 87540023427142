import { ErrorInfo } from 'react';

export const PAGE_TRANSITION_EVENT = 'onPageTransition';

export function dispatchPageTransitionEvent(prevPathname: string, pathname: string) {
  const pageTransitionEvent = new CustomEvent<{ prevPathname: string; pathname: string }>(PAGE_TRANSITION_EVENT, {
    detail: {
      pathname,
      prevPathname,
    },
  });

  window.dispatchEvent(pageTransitionEvent);
}

export const ERROR_EVENT = 'onTrackError';

export function dispatchErrorEvent(errorToLog: Error, errorInfo: ErrorInfo | undefined) {
  const onTraceErrorEvent = new CustomEvent<{ error: Error; info: ErrorInfo | undefined }>(ERROR_EVENT, {
    detail: {
      error: errorToLog,
      info: errorInfo,
    },
  });

  window.dispatchEvent(onTraceErrorEvent);
}
