import { AlertTitle } from '@mui/material';
import { Alert } from '@chronosphereio/chrono-ui';

export function CliLoginAlert() {
  return (
    <Alert severity="info" sx={(theme) => ({ marginTop: -theme.spacing(2), marginBottom: theme.spacing(4) })}>
      <AlertTitle>Script-initiated log in</AlertTitle>
      {`Your script session requested authentication. You'll be able to close this browser after a successful log in.`}
    </Alert>
  );
}
