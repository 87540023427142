import { SxProps } from '@chronosphereio/chrono-ui';

/**
 * Combines sx props from multiple sources. Useful when creating a component and
 * you want consumers to be able to provide SxProps that should be combined with
 * some built-in styles.
 */
export function combineSx(...sxProps: Array<SxProps | undefined>): SxProps {
  return sxProps.flatMap((sx) => {
    if (sx === undefined) return [];
    if (Array.isArray(sx)) return sx;
    return [sx];
  });
}
