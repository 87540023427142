import { useState, useCallback } from 'react';
import constate from 'constate';
import { RelatedResources } from '@/model/active-resource';

/**
 * State used by the App Header
 *
 * title: title of the page
 * crumbs: breadcrumbs representing the page ancestors
 * prefix: component prefixing the page title, such as status icon or favorite button
 * relatedResources: resources of the same type, members of the same team or collection
 * pageAccess: renders a badge that indicates the page is either "Internal only" or "Preview"
 * editMode: renders a badge that says "Edit mode"
 */
export interface HeaderState {
  title: string;
  crumbs?: Array<{ name: string; href: string }>;
  prefix?: React.ReactNode;
  relatedResources?: RelatedResources;
  pageAccess?: 'preview' | 'internal';
  pinnedScope?: boolean;
  editMode?: boolean;
}

// Provide header state and functions to update or replace the state
function useHeaderValues() {
  const [headerState, setHeaderState] = useState<HeaderState>({ title: '' });

  const updateHeaderState = useCallback((updatedHeaderState: Partial<HeaderState>) => {
    setHeaderState((headerState) => ({ ...headerState, ...updatedHeaderState }));
  }, []);

  return { headerState, updateHeaderState, replaceHeaderState: setHeaderState };
}

// Export the context provider and "use" hook
export const [AppHeaderProvider, useAppHeader] = constate(useHeaderValues);
